import './Styles/Home.css';
import './Styles/Animation.css';
// import LogoReveal from './LogoReveal';
import Navbar from './Navbar';
import Hero from './Hero';
import { useEffect, useState } from 'react';
import Advantages from './Advantages';
import Team from './Team';
import Footer from './Footer';
import Services from './Services';
import PayModel from './PayModel';
import Testimonials from './Testimonials';
import ReadyToChoose from './ReadyToChoose';
import HoveringBar from './HoveringBar';

function Home() {
  const [loaded,setLoaded]=useState(false);
  const dynamicText = ["Experienced professionals","Zero discrepancies","Better financial management","Exceptional business growth","Experienced professionals","Zero discrepancies","Better financial management","Exceptional business growth"]
  const dynamicHeadingText = ["growth.","focus.","capabilities.","impact."]

  useEffect(()=>{



    setTimeout(()=>{
      setLoaded(true);
    },8000)
  })
  return (
    <div className="Home">
      <Navbar noAnimation={false}/>
      {
        loaded &&
        <>
        <Hero heading="Outsourced financial services that multiply your" dynamicHeadingText={dynamicHeadingText} extraText="Outsourcing only makes sense if you choose the right partner. And we’ll prove that to you with our experience, knowledge, and dedication."/>
        <Advantages staticText="Companies hire us for" dynamicText={dynamicText}/>
        <Team />
        <Services />
        <Testimonials />
        <PayModel />
        <ReadyToChoose />
        <Footer />
        <HoveringBar />
        </>
        
      }
      
    </div>
  );
}

export default Home;
