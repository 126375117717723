import './Styles/AdminPanel.css';
import './Styles/Animation.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getFirestore,collection, getDocs, deleteDoc,doc, addDoc,updateDoc } from "firebase/firestore"; 
function AdminPanel() {
  const navigate=useNavigate();
  let [formData,setFormData]=useState([]);
  let [pricingData,setPricingData]=useState([]);
  let [jobData,setJobData]=useState([]);
  useEffect(()=>{
    let loggedIn=sessionStorage.getItem("loggedIn");
    if(!loggedIn || loggedIn===null){
      navigate("/AdminPanel");
    }
    getData();
    getPricingData();
    getJobData();
  },[])
  
  
  

  const firebaseConfig = {
    apiKey: "AIzaSyDIO56H7sMsth2kvTPB5-a79M7o8IockkE",
    authDomain: "crossright-50fa6.firebaseapp.com",
    projectId: "crossright-50fa6",
    storageBucket: "crossright-50fa6.appspot.com",
    messagingSenderId: "986658181564",
    appId: "1:986658181564:web:7ae35c1c477168cdd9dbb9",
    measurementId: "G-Q797J9D3QG"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  async function getData(){
    let dataArr=[];
    const querySnapshot = await getDocs(collection(db, "usersFormData"));
    querySnapshot.forEach((doc) => {
      dataArr.push({...doc.data(),"id":doc.id});
    });
    setFormData(dataArr);
  }
  async function getPricingData(){
    let dataArr=[];
    const querySnapshot = await getDocs(collection(db, "usersPricingTabData"));
    querySnapshot.forEach((doc) => {
      dataArr.push({...doc.data(),"id":doc.id});
    });
    setPricingData(dataArr);
  }
  async function getJobData(){
    let dataArr=[];
    const querySnapshot = await getDocs(collection(db, "jobPostings"));
    querySnapshot.forEach((doc) => {
      dataArr.push({...doc.data(),"id":doc.id});
    });
    setJobData(dataArr);
  }
  async function deleteData(id){
    await deleteDoc(doc(db, "usersFormData",id ));
  }
  async function deletePricingData(id){
    await deleteDoc(doc(db, "usersPricingTabData",id ));
  }

  async function deleteJobData(id){
    await deleteDoc(doc(db, "jobPostings",id ));
  }
  async function deleteAllData(formData){
    formData.forEach((value)=>{
      deleteData(value.id);
    })
  }
  async function deleteAllPricingData(pricingData){
    pricingData.forEach((value)=>{
      deletePricingData(value.id);
    })
  }
  async function deleteAllJobData(jobData){
    jobData.forEach((value)=>{
      deleteJobData(value.id);
    })
  }
  async function postJob(){
    let jobDetails={
      WFO:document.getElementById("jobWFO").value,
      category:document.getElementById("jobCategory").value,
      expRequired: document.getElementById("expRequired").value,
      googleFormLink:document.getElementById("googleFormLink").value,
      jobStatus:true,
      jobTitle:document.getElementById("jobTitle").value,
      location:document.getElementById("jobLocation").value,
      noOfPositions:document.getElementById("noOfPositions").value,
      postedDate:new Date().toDateString()
    }
    console.log(jobDetails);


    try {
      const docRef = await addDoc(collection(db, "jobPostings"), jobDetails);
      document.getElementById("jobWFO").value=""
      document.getElementById("jobCategory").value=""
      document.getElementById("expRequired").value=""
      document.getElementById("googleFormLink").value=""
      document.getElementById("jobTitle").value=""
      document.getElementById("jobLocation").value=""
      document.getElementById("noOfPositions").value=""
    } catch (e) {
      console.error("Error Posting Job: ", e);
    }


  }

  async function updateJobStatus(id,status){
      let sure=window.confirm("You want to change the status for this job?")
      if(sure){
        try {
          console.log("Dhyey",typeof(status),id);
          const docRef = doc(db, "jobPostings", id);
           await updateDoc(docRef, {
            jobStatus:!status
          });
        } catch (e) {
          console.error("Error Updating Job: ", e);
        }
        alert("changed");
      }
  }

  function getStep3Value(value){
      let newVal="";
      if(/^[0-9]+$/.test(value.split(",")[0])){
        let values=value.split(",")
        let k=0;
        console.log("Yunus",values.length)
        for(let i=0;i<values.length/3;i++){
          newVal+="Preparer:"+values[k]+"    "
          k++;
          newVal+="Reviewer:"+values[k]+"    "
          k++;
          newVal+="Manager:"+values[k]+"    "
          k++;
          newVal+="   ************     "
        }
       
      }
      else{
        newVal=value
      }
      return newVal
  }

  return (
    <div className="AdminPanel">
      <h1>Crossright Admin Panel</h1>
      <h2>Form Data</h2>
      <div className='AdminPanel-FormData'>
        <table border="1">
          <tr>
            <th>Name</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Type of Service</th>
            <th>Thoughts</th>
            <th><button className='AdminPanel-FormData-delete' onClick={()=>{
            deleteAllData(formData)
            getData();
          }}>Delete All</button></th>
          </tr>
        {formData.map((value,key)=>{
          return(


        <tr key={key}>
          
          <td>{value.fullName}</td>
          <td >{value.companyName}</td>
          <td>{value.email}</td>
          <td >{value.typeOfService}</td>
          <td>{value.thoughts}</td>
          <td><button className='AdminPanel-FormData-delete' onClick={()=>{
            deleteData(value.id)
            getData();
            // console.log(value)
          }}>Delete</button></td>
        </tr>)
        })}
        </table>
        {/* <div className='AdminPanel-FormData-card'>
          <div className='AdminPanel-FormData-card-fullName'>ABC</div>
          <div className='AdminPanel-FormData-card-companyName'>XYZ</div>
          <div className='AdminPanel-FormData-card-email'>hakjsf</div>
          <div className='AdminPanel-FormData-card-typeOfService'>asfasf</div>
          <div className='AdminPanel-FormData-card-thoughts'>asfasf</div>
        </div>
        <div className='AdminPanel-FormData-card'>
          <div className='AdminPanel-FormData-card-fullName'>ABC</div>
          <div className='AdminPanel-FormData-card-companyName'>XYZ</div>
          <div className='AdminPanel-FormData-card-email'>hakjsf</div>
          <div className='AdminPanel-FormData-card-typeOfService'>asfasf</div>
          <div className='AdminPanel-FormData-card-thoughts'>asfasf</div>
        </div> */}
      </div>
      <h2>Pricing Tab Data</h2>
      <div className='AdminPanel-FormData'>
        <table border="1">
          <tr>
            <th>Step 1</th>
            <th>Step 2</th>
            <th>Step 3</th>
            <th>Step 4</th>
            <th>Step 5</th>
            <th><button className='AdminPanel-FormData-delete' onClick={()=>{
            deleteAllPricingData(pricingData)
            getData();
          }}>Delete All</button></th>
          </tr>
        {pricingData.map((value,key)=>{
          return(


        <tr key={key}>
          
          <td>{value.Step1}</td>
          <td >{value.Step2}</td>
          <td style={{"max-width":"10vw","overflow-wrap": "break-word"}}>
            {getStep3Value(value.Step3)}
          </td>
          <td >{value.Step4}</td>
          <td>{value.Step5}</td>
          <td><button className='AdminPanel-FormData-delete' onClick={()=>{
            deletePricingData(value.id)
            getPricingData();
            // console.log(value)
          }}>Delete</button></td>
        </tr>)
        })}
        </table>
        </div>
        <h2>Post New Job</h2>
        <div className='AdminPanel-JobForm'>
          <input type="text" placeholder="Job Title" id="jobTitle"/>
          <input type="number" placeholder="No of Positions" id='noOfPositions'/>
          <input type="text" placeholder="Location" id='jobLocation'/>
          <input type="number" placeholder="Experience Required" id='expRequired'/>
          Category:
          <select id='jobCategory'>
            <option>Accounting and Bookkeeping</option>
            <option>Taxation</option>
            <option>Audit and Assurance</option>
            <option>Business Development / CRM</option>
            <option>Others</option>
          </select>
          WFO:
          <select id='jobWFO'>
            
            <option>Yes</option>
            <option>No</option>
          </select>
          <input type="text" placeholder='Enter Google Form Link' id="googleFormLink" />
          <button onClick={()=>{
            postJob();
            getJobData();
          }}>Post this job</button>
        </div>
        <div className='AdminPanel-JobData'>
          <tr>
              <th>Job Title</th>
              <th>No of Positions</th>
              <th>Location</th>
              <th>Experience Required</th>
              <th>Category</th>
              <th>WFO</th>
              <th>Google Form Link</th>
              <th>Status</th>
              <th>Posted Date</th>
              <th><button className='AdminPanel-FormData-delete' onClick={()=>{
              deleteAllJobData(jobData)
              getJobData();
            }}>Delete All</button></th>
            </tr>
            {jobData.map((value,key)=>{
          return(


        <tr key={key}>
          
          <td>{value.jobTitle}</td>
          <td >{value.noOfPositions}</td>
          <td>{value.location}</td>
          <td >{value.expRequired}</td>
          <td>{value.category}</td>
          <td>{value.WFO===true?"Yes":"No"}</td>
          <td><a href={value.googleFormLink} target="_blank" rel="noreferrer">Click Here</a></td>
          <td onDoubleClick={()=>{
            updateJobStatus(value.id,value.jobStatus);
            getJobData();
          }
          }>{value.jobStatus===true?"Active":"Not Active"}</td>
          <td>{value.postedDate}</td>
          <td><button className='AdminPanel-FormData-delete' onClick={()=>{
            deleteJobData(value.id)
            getJobData();
            // console.log(value)
          }}>Delete</button></td>
        </tr>)
        })}
        </div>
    </div>
  );
}

export default AdminPanel;
