import './Styles/HowWeDoSecurity.css';
import './Styles/Animation.css';
import privacy from './Resources/privacy.svg'
import datasecurity from './Resources/dataSecurity.svg'
import access from './Resources/access.svg'
import availability from './Resources/availability.svg'
import { useEffect } from 'react';

function HowWeDoSecurity() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="HowWeDoSecurity">
      <h1 className='HowWeDoSecurity-header watch'>Security Uncompromised</h1>
      <div className="HowWeDoSecurity-main">
        <div className="HowWeDoSecurity-main-left watch">
          Security is non-negotiable for us. We are as serious about the security of your data as the vault in charge of the Federal Reserve. We are in the process of getting ISO/IEC 27001:2013 certification for the company.
        </div>
        <div className="HowWeDoSecurity-main-right">
        <div className="HowWeDoSecurity-main-right-card">
          <img src={privacy} alt="Privacy" className='watch'/>
          <div className="HowWeDoSecurity-main-right-card-text watch">Privacy</div>
        </div>
        <div className="HowWeDoSecurity-main-right-card">
          <img src={datasecurity} alt="Data Security" className='watch'/>
          <div className="HowWeDoSecurity-main-right-card-text watch">Data Security</div>
        </div>
        <div className="HowWeDoSecurity-main-right-card">
          <img src={access} alt="Access" className='watch'/>
          <div className="HowWeDoSecurity-main-right-card-text watch">Access Control</div>
        </div>
        <div className="HowWeDoSecurity-main-right-card">
          <img src={availability} alt="availability" className='watch'/>
          <div className="HowWeDoSecurity-main-right-card-text watch">Availability</div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default HowWeDoSecurity;
