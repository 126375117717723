import './Styles/HowWeDoHero.css';
import './Styles/Animation.css';
import howwedopic from './Resources/HowWeDo.svg';
import { useEffect } from 'react';


function HowWeDoHero() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="HowWeDoHero">
      <div className='HowWeDoHero-main'>
        
        <h1 className='HowWeDoHero-main-header watch2'>Financial outsourcing is <span>much more</span> than you think.</h1>
        <h3 className='HowWeDoHero-main-header-sub watch2'>
          Clients hire CrossRight for its <span>financial proficiency, technical ability, and scalable structure</span> but stay because project relationships turn into trusted partnerships.
        </h3>
      </div>
      <div className='chart-svg watch2'>
        <img src={howwedopic} alt="how we do" />
      </div>
    </div>
  );
}

export default HowWeDoHero;
