import { useEffect } from 'react';
import './Styles/AboutWho.css';

import './Styles/Animation.css';

function AboutWho() {

  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="AboutWho">
      
      <div className='AboutWho-card'>
        <div className='AboutWho-card-title watch'>
        What We Do Best?
        </div>
        <div className='AboutWho-card-detail watch'>
        Working with <span>40+ clients</span> has taught us that we are best at quick and effective delivery of outsourced financial services through our talented & experienced resources.
<br/><br/>
Our focused and methodical approach to providing financial services combined with our technical expertise in using required software is our core strength.

        </div>
      </div>
      <div className='AboutWho-card'>
        <div className='AboutWho-card-title watch'>Who We Are?</div>
        <div className='AboutWho-card-detail watch'>
        Quality-obsessed professionals with one aim - To deliver the best services, with swift execution and the highest level of ethics. 
<br/><br/>
When we started CrossRight, our mission was to make a difference in the overcrowded outsourcing market by offering premium services at a reasonable price. And that’s what makes us push our limits, every single day.

        </div>
      </div>
    </div>
  );
}

export default AboutWho;
