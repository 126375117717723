import './Styles/AboutPhotos.css';

import office6 from './Resources/office6.jpeg'
import office7 from './Resources/office7.jpeg'
import office5 from './Resources/office5.jpeg'
import office4 from './Resources/office4.jpeg'
import office3 from './Resources/office3.jpeg'
import office8 from './Resources/office8.jpeg'

import './Styles/Animation.css';
import { useEffect } from 'react';

function AboutPhotos() {

  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="AboutPhotos">
      <h1>
        Photos
      </h1>
      <div className='AboutPhotos-grid'>
        <div className='AboutPhotos-grid-Photo'>
          <img src={office8} alt="teamPic" />
        </div>
        <div className='AboutPhotos-grid-Photo'>
        <img src={office3} alt="teamPic" />
        </div>
        <div className='AboutPhotos-grid-Photo'>
        <img src={office7} alt="teamPic" />
        </div>
        <div className='AboutPhotos-grid-Photo'>
        <img src={office4} alt="teamPic" />
        </div>
        <div className='AboutPhotos-grid-Photo'>
        <img src={office6} alt="teamPic" />
        </div>
        {/* <div className='AboutPhotos-grid-Photo'>
        <img src={photos} alt="teamPic" />
        </div> */}
      </div>
    </div>
  );
}

export default AboutPhotos;
