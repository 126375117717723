import './Styles/Footer.css';
import './Styles/Animation.css';
import LogoReveal2 from './LogoReveal2';
import indiaFlag from './Resources/indiaFlag.png';
import canadaFlag from './Resources/canadaFlag.svg';
import USAFlag from './Resources/USAFlag.svg';
import AusFlag from './Resources/AustraliaFlag.webp';
import calendar from './Resources/calendar.svg';

import {useNavigate } from "react-router-dom";
import { useEffect } from 'react';
function Footer() {
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate()},[])
  const navigate=useNavigate();
  return (
    <div className="Footer">
      <div className='Footer-left'>
        {/* <div className='Footer-left-action'>
          <input type="text" placeholder="Your email" />
          <button>Subscribe</button>
        </div> */}
        <div className='Footer-left-link' onClick={()=>{
             navigate("/TermsOfServices");
          }}>
          Terms of Service 
        </div>
        <div className='Footer-left-link' onClick={()=>{
             navigate("/Privacy");
          }}>
          Privacy Policy
        </div>
      </div>
      <div className='Footer-right'>
        <div className='Footer-right-address'>
          <img src={indiaFlag} alt="India Flag" />
          <h3>Ahmedabad, India</h3>
        </div>
        <div className='Footer-right-address'>
          <img src={USAFlag} alt="USA Flag" />
          <h3>Ohio, USA</h3>
          
        </div>
        <div className='Footer-right-address'>
          <img src={canadaFlag} alt="Canada Flag" />
          <h3>Canada</h3>
        </div>
        <div className='Footer-right-address'>
          <img src={AusFlag} alt="Australia Flag" />
          <h3>Australia</h3>
        </div>
      </div>
      {/* <div className='schedule'>
        <a href='https://calendly.com/crossright/' target="_blank"><img src={calendar} alt="Schedule" /></a>
        <a href='https://calendly.com/crossright/' target="_blank"><h3>Schedule Meeting</h3></a>
      </div> */}
    </div>
  );
}

export default Footer;
