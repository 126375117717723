import './Styles/HowWeDoTools.css';
import './Styles/Animation.css';
import intuit from './Resources/intuit.png'
import xero from './Resources/xero.png'
import tally from './Resources/tally.png'
import wave from './Resources/wave.png'
import sage from './Resources/sage.svg'
import oracle from './Resources/oracle.png'
import property from './Resources/propertyware.png'
import appfolio from './Resources/AppFolio.png'
import buildium from './Resources/Buildium.png'
import caseware from './Resources/caseware.png'
import drake from './Resources/drake.png'
import turbotax from './Resources/IntuitTurboTax.png'
import proconnect from './Resources/intuitproconnect.png'
import thomson from './Resources/thomson.png'
import opera from './Resources/oracleOpera.png'
import micros from './Resources/Opera.png'
import sap from './Resources/sap.png'
import adp from './Resources/adp.png'
import bill from './Resources/bill.png'
import { useEffect } from 'react';

function HowWeDoTools() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="HowWeDoTools">
      <h1 className='HowWeDoTools-header watch'>Tools Of Our Trade</h1>
      <h2 className='HowWeDoTools-sub watch'>Software that we actually know and have worked on</h2>
      <div className='HowWeDoTools-main'>
        <div className='HowWeDoTools-main-card'>
          <div className='HowWeDoTools-main-card-title watch'>Accounting</div>
          <div className='HowWeDoTools-main-card-imgs watch'>
            <img  src={intuit} alt="company"/>
            
            <img  src={wave} alt="company"/>
            <img  src={sage} alt="company"/>
            <img  src={oracle} alt="company"/>
            <img  src={xero} alt="company"/>
            <img  src={tally} alt="company"/>

          </div>
        </div>
        <div className='HowWeDoTools-main-card'>
          <div className='HowWeDoTools-main-card-title watch'>Property Management</div>
          <div className='HowWeDoTools-main-card-imgs watch'>
            
            <img  src={appfolio} alt="company"/>
            <img  src={property} alt="company"/>
            <img  src={buildium} alt="company"/>
            
          </div>
        </div>
        <div className='HowWeDoTools-main-card'>
          <div className='HowWeDoTools-main-card-title watch'>Audit</div>
          <div className='HowWeDoTools-main-card-imgs watch'>
            <img  src={caseware} alt="company"/>
          </div>
        </div>
        <div className='HowWeDoTools-main-card'>
          <div className='HowWeDoTools-main-card-title watch'>Tax Returns</div>
          <div className='HowWeDoTools-main-card-imgs watch'>
            <img  src={drake} alt="company"/>
            <img  src={turbotax} alt="company"/>
            <img  src={proconnect} alt="company"/>
            <img  src={thomson} alt="company"/>
          </div>
        </div>
        <div className='HowWeDoTools-main-card'>
          <div className='HowWeDoTools-main-card-title watch'>Retail Segment</div>
          <div className='HowWeDoTools-main-card-imgs watch'>
            <img  src={sap} alt="company"/>
            <img  src={micros} alt="company"/>
            <img  src={opera} alt="company"/>
          </div>
        </div>
        <div className='HowWeDoTools-main-card'>
          <div className='HowWeDoTools-main-card-title watch'>Payroll & AR/AP Management</div>
          <div className='HowWeDoTools-main-card-imgs watch'>
            <img  src={adp} alt="company"/>

            <img  src={bill} alt="company"/>
            <img  src={intuit} alt="company"/>
            
          </div>
        </div>
        
      </div>
      <div className='HowWeDoTools-disclaimer watch'>
      *All trademarks, logos and brand names are the property of their respective owners. All company, product and service names used in this document are for identification purposes only. Use of these names, trademarks and brands does not imply endorsement.
      </div>
    </div>
  );
}

export default HowWeDoTools;
