import './Styles/Testimonials.css';
import './Styles/Animation.css';
import { useEffect, useState } from 'react';
import Testimonial from './Testimonial';
import personImg from "./Resources/person.jpeg";
import companyLogo from "./Resources/company-logo.png";

function Testimonials() {
  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate()},[])

  return (
    <>

      <div className="Testimonials">
        <h1 className='watch'>Testimonials</h1>
        <div className='Testimonials-group'>
        <Testimonial className='Testimonial'  content={<>"Professional, helpful, always available and good value for money.<br/><br/>
Certainly the best bookkeeping & Auditing solution we have found in almost ten years of business."</>}
        personName="Saleem Waheed"
        companyName="AmeriCloud Solutions Inc"
        designation="QA Analyst"
        />
        <Testimonial className='Testimonial' dark={true}  content={<>"Naman was very knowledgeable, extremely helpful and I really enjoyed preparing my taxes with him. <br/><br/> I would highly recommend Tax team to everyone."</>}
        personName="Mansur Motorwala"
        companyName=""
        designation="QA Analyst"
        />
        <Testimonial className='Testimonial' dark={true}   content={<>"We have used services of Nirav Soni.He works for us as a Virtual CFO. <br/><br/>We highly enough of CrossRight team for their prompt efficient quality service."<br/></>}
        personName="Manoj Patel "
        companyName={<>Bluelight<br/>Cloud LLC</>}
        designation="QA Analyst"
        />
        <Testimonial className='Testimonial'  content={<>"Very helpful, straightforward. <br/><br/>Was very concerned starting this process but Moiz was a great help and answered every question I had. Very straightforward with the process and cost.<br/><br/> They were great to work with and I would recommend them to anyone who needs help."</>}
        personName="Rahul Agarwal"
        companyName="Polygone INC"
        designation="QA Analyst"
        />
        <Testimonial className='Testimonial'  content={<>"We are Periodicallly taken Audit services to carry out annual Financial Audit for our Clients. <br/><br/>Moiz and his team are excellent & fully understands client's business, his time management skills and attention to detail ensures a speedy turnaround enabling us to meet deadlines in getting our client's annual accounts.<br/><br/> We cannot speak highly enough for their prompt efficient service."</>}
        personName="CPA Manen Kothari"
        companyName="S.K. Taxes, CPA"
        designation="QA Analyst"
        />
        </div>
        
      </div>
    
    </>
    
  );
}

export default Testimonials;
