import './Styles/PricingDialog.css';

import './Styles/Animation.css';
import { useState,useEffect } from 'react';


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore,collection, addDoc  } from "firebase/firestore";

function PricingDialog() {

    function Animate(){
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // It's visible. Add the animation class here!
              if(entry.target.classList.contains("watch2")){
                entry.target.classList.add("inView2");
              }
              else if(entry.target.classList.contains("watch")){
                entry.target.classList.add("inView");
              }
              
            }
            else{
              entry.target.classList.remove("inView");
              entry.target.classList.remove("inView2");
            }
          });
        });
        
        // Tell the observer which elements to track
        let elementsToWatch=document.querySelectorAll('.watch');
        elementsToWatch.forEach(element=>{
          observer.observe(element);
        })
        let elementsToWatch2=document.querySelectorAll('.watch2');
        elementsToWatch2.forEach(element=>{
          observer.observe(element);
        })
      
      }
      useEffect(()=>{Animate();},[])
    const firebaseConfig = {
        apiKey: "AIzaSyDIO56H7sMsth2kvTPB5-a79M7o8IockkE",
        authDomain: "crossright-50fa6.firebaseapp.com",
        projectId: "crossright-50fa6",
        storageBucket: "crossright-50fa6.appspot.com",
        messagingSenderId: "986658181564",
        appId: "1:986658181564:web:7ae35c1c477168cdd9dbb9",
        measurementId: "G-Q797J9D3QG"
      };
    
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      const db = getFirestore(app);
    

    const TeamDialog={
        "01. Choose Engagement Model":{
            "Choose between hiring a dedicated team or on a project basis.":
            {
                "options":["Team","Project"]
            }
        },
        "02. People":{
            "Start by picking the right expert you need for the job.":
            {
                "options":["Management Accountant","Bookkeeper","Audit Assistant","Payroll Accountant","Tax Assistant","Financial Analyst"]
            }
        },
        "03. Reviewers":{
            "How many reviewers will you require for your work?":
            {
                "fib":["No of Preparer required for Management Accountant","No of Reviewer required for Management Accountant","No of Manager required for Management Accountant",
                "No of Preparer required for Bookkeeper","No of Reviewer required for Bookkeeper","No of Manager required for Bookkeeper",
                "No of Preparer required for Tax Assistant","No of Reviewer required for Tax Assistant","No of Manager required for Tax Assistant",
                "No of Preparer required for Audit Assistant","No of Reviewer required for Audit Assistant","No of Manager required for Audit Assistant",
                "No of Preparer required for Payroll Accountant","No of Reviewer required for Payroll Accountant","No of Manager required for Payroll Accountant",
                "No of Preparer required for Financial Analyst","No of Reviewer required for Financial Analyst","No of Manager required for Financial Analyst",
                    ]
            }
        }, 
        "04. Project Duration":{
            "How long do you expect us to work with your team?":
            {
                "fib":["Enter number of months"]
            }
        },
        "05. Get Your Estimate":{
            "You’re almost there. Before we start crunching numbers, we would like to know a little about you and your expectations about this project.":
            {
                "fib":["Name","Email","Describe your requirements"]
            }
        }
    }
    const projectDialog={
        "01. Choose Engagement Model":{
            "Choose between hiring a dedicated team or on a project basis.":
            {
                "options":["Team","Project"]
            }
        },
        "02. Services":{
            "Now, select the service you want.":
            {
                "options":["Accounting & Bookkeeping","Management Accounting","Audit & Assurance","Tax Return Preparation & filing","Payroll Processing & filing","Financial Analysis"]
            }
        },
        "03. Software":{
            "Which software you would like us to work with?":
            {
                "fib":["Accounting & Bookkeeping","Audit & Assurance","Tax Return Preparation & filing","Financial Analysis","Payroll Processing & filing","ERP","Other"]
            }
        }, 
        "04. Project Duration":{
            "How long do you expect us to work with your team?":
            {
                "fib":["Enter number of months"]
            }
        },
        "05. Get Your Estimate":{
            "You’re almost there. Before we start crunching numbers, we would like to know a little about you and your expectations about this project.":
            {
                "fib":["Name","Email","Describe your requirements"]
            }
        }
    }


   const [model,setModel]=useState(TeamDialog);
   const [step,setStep]=useState("01. Choose Engagement Model");
   const [stepOutput,setStepOutput]=useState(["","","","",""]);

   function selectOption(e){
    if(step==="01. Choose Engagement Model"){
        let options=document.getElementsByClassName("PricingDialog-main-right-options-option");
        for(let i=0;i<options.length;i++){
            if(options[i].classList.contains("PricingDialog-main-right-options-option-active")){
                options[i].classList.remove("PricingDialog-main-right-options-option-active");
            }
        }
        e.target.classList.add("PricingDialog-main-right-options-option-active");
        if(e.target.innerText==="Team")
            setModel(TeamDialog)
        else
            setModel(projectDialog)
    }
    else{
        if(e.target.classList.contains("PricingDialog-main-right-options-option-active")){
            e.target.classList.remove("PricingDialog-main-right-options-option-active");
        }
        else{
            e.target.classList.add("PricingDialog-main-right-options-option-active");
        }
    }
   }

    function changeStep(){
        let options=document.getElementsByClassName("PricingDialog-main-right-options-option");
        let selected=document.getElementsByClassName("PricingDialog-main-right-options-option-active")
        if((options.length>0 && selected.length>0)){
            for(let i=0;i<Object.keys(model).length;i++){
                if(Object.keys(model)[i]===step){
                    let duplOutput=stepOutput;
                    let tempOutput=[];
                    for(let j=0;j<selected.length;j++){
                        tempOutput.push(selected[j].innerText);
                    }
                    duplOutput[i]=tempOutput;
                    setStepOutput(duplOutput)
                    if(i<4){
                        setStep(Object.keys(model)[i+1])
                        clearSelection();
                    }
                    
                }
            }
        }
        else if(options.length===0){
            let blanks=document.getElementsByClassName("PricingDialog-main-right-options-fib")
            for(let i=0;i<Object.keys(model).length;i++){
                if(Object.keys(model)[i]===step){
                    let duplOutput=stepOutput;
                    let tempOutput=[];
                    for(let j=0;j<blanks.length;j++){
                        tempOutput.push(blanks[j].value);
                    }
                    duplOutput[i]=tempOutput;
                    setStepOutput(duplOutput)
                    if(i<4){
                        setStep(Object.keys(model)[i+1])
                        clearSelection();
                    }
                    
                }
            }
        }
        
    }
    function prevStep(){
       
        for(let i=0;i<Object.keys(model).length;i++){
            if(Object.keys(model)[i]===step){
                if(i>=1){
                    setStep(Object.keys(model)[i-1])
                    clearSelection();
                    
                        setTimeout(()=>{
                            makeSelection(Object.keys(model)[i-1],i-1)
                        },250);
                    
                    
                }
                
            }
        }
        
    }
    // function changeStep(e){
    //     setStep(e.target.innerText);
    // }

    function makeSelection(stepName,stepNo){
        // stepOutput[stepNo];
        if(Object.keys(model[stepName][Object.keys(model[stepName])[0]])[0]==="options"){
            let currentoptions=document.getElementsByClassName("PricingDialog-main-right-options-option")
            let currentSelected=stepOutput[stepNo];
            for(let i=0;i<currentoptions.length;i++){
                for(let j=0;j<currentSelected.length;j++){
                    if(currentoptions[i].innerText===currentSelected[j]){
                        // console.log("Yes");
                        currentoptions[i].classList.add("PricingDialog-main-right-options-option-active")
                    }
                }
            }
        }
        else{
            let currentblanks=document.getElementsByClassName("PricingDialog-main-right-options-fib");
            for(let i=0;i<currentblanks.length;i++){
                if(stepOutput[stepNo][i]!==undefined)
                    currentblanks[i].value=stepOutput[stepNo][i];
            }
        }
        
    }

    function clearSelection(){
        let options=document.getElementsByClassName("PricingDialog-main-right-options-option");
        for(let i=0;i<options.length;i++){
            if(options[i].classList.contains("PricingDialog-main-right-options-option-active")){
                options[i].classList.remove("PricingDialog-main-right-options-option-active");
            }
        }
        let blanks=document.getElementsByClassName("PricingDialog-main-right-options-fib");
        for(let i=0;i<blanks.length;i++){
            blanks[i].value="";
        }
    }

    function displayAlertMessage(message){
        document.getElementById("AlertMessage").innerHTML=message
        document.getElementById("AlertMessage").classList.add("AlertMessage-show");
        setTimeout(()=>{
          document.getElementById("AlertMessage").classList.remove("AlertMessage-show");
        },5000)
      }
    async function submitForm(){

        let blanks=document.getElementsByClassName("PricingDialog-main-right-options-fib")
        for(let i=0;i<Object.keys(model).length;i++){
            if(Object.keys(model)[i]===step){
                let duplOutput=stepOutput;
                let tempOutput=[];
                for(let j=0;j<blanks.length;j++){
                    
                        let value=blanks[j].value;
                        if(value!=="")
                            tempOutput.push(value);
                   
                }
                duplOutput[i]=tempOutput;
                setStepOutput(duplOutput)
                if(i<4){
                    setStep(Object.keys(model)[i+1])
                    clearSelection();
                }
                
            }
        }



        try {
            const docRef = await addDoc(collection(db, "usersPricingTabData"), {
              Step1: stepOutput[0],
              Step2: stepOutput[1].join(","),
              Step3: stepOutput[2].join(","),
              Step4: stepOutput[3].join(","),
              Step5: stepOutput[4].join(","),
            });
            displayAlertMessage("We have received your data, we shall contact you in 24 hours.")
            clearSelection();
            setStepOutput(["","","","",""]);
          } catch (e) {
            console.error("Error adding document: ", e);
            displayAlertMessage("We are facing issues at our end, please try after sometime.")
          }
    }
    

    function selectionExists(option){
        let present=false;
        stepOutput[1].forEach(selected=>{
            console.log(selected,option);
            if(option.includes(selected)){
                console.log("I am in")
                present=true;
            }
            if(option==="ERP" || option==="Other"){
                present=true;
            }
                
        })
       return present;
    }
  return (
    <>
    <div className="PricingDialog">
      <h1 className='PricingDialog-header watch'>Let Us Get You The Best Estimate</h1>
      <h2 className='PricingDialog-sub watch'>You're just <span>five steps </span>away from getting our best quote. Share your requirements, and let us do the rest.</h2>
      <div className='PricingDialog-main'>
        <div className='PricingDialog-main-left watch'>
            <ul>
                {Object.keys(model).map((key)=>{
                    return <li key={key} className={`PricingDialog-main-left-step ${key===step?'PricingDialog-main-left-step-active':''}`} >{key}</li>
                })}
            </ul>
        </div>
        <div className='PricingDialog-main-right'>
            <div className='PricingDialog-main-right-question watch'>
            {Object.keys(model[step])[0]}
            </div>
            <div className='PricingDialog-main-right-options watch'>
                {Object.keys(model[step][Object.keys(model[step])[0]])[0]==="options"?
                    model[step][Object.keys(model[step])[0]]["options"].map((option,key)=>{
                        return <div className='PricingDialog-main-right-options-option' key={key}
                        onClick={(e)=>{
                            selectOption(e); 
                        }}
                        
                        >{option}</div>
                    }):
                    (step==="03. Reviewers" || step==="03. Software")?
                        model[step][Object.keys(model[step])[0]]["fib"].map((option,key)=>{
                            return selectionExists(option)===true?
                            <input placeholder={option} type={option.includes("No of")===true?"number":"text"} className='PricingDialog-main-right-options-fib' key={key}></input>:
                            null
                        }):
                        model[step][Object.keys(model[step])[0]]["fib"].map((option,key)=>{
                            // return option.includes("Bookkeeper")===true?
                            return <input placeholder={option} type={option.includes("months")===true?"number":"text"} className='PricingDialog-main-right-options-fib' key={key}></input>
                            // null
                        })
                    }
                    
            </div>
            <div className='PricingDialog-main-right-next watch'>
                    {step!=="01. Choose Engagement Model" && <button onClick={()=>{
                        prevStep();
                    }}>Prev</button>}
                    {step!=="05. Get Your Estimate" && <button onClick={()=>{
                        changeStep();
                    }}>Next</button>}
                    {step==="05. Get Your Estimate" && <>
                    <span>By submitting you agree to the Crossright’s cookie policy and Privacy Policy</span>

                    <button onClick={()=>{
                        submitForm();
                    }}>Submit</button>
                    
                    </>}
                    
                
            </div>
        </div>
      </div>
    </div>
    <div id='AlertMessage' className='AlertMessage'>
          
          </div>
    </>
  );
}

export default PricingDialog;
