import { useEffect } from 'react';
import './Styles/HowWeDoProcess.css';
import './Styles/Animation.css';

function HowWeDoProcess() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }

  useEffect(()=>{
    Animate();
    let processes=document.getElementsByClassName("HowWeDoProcess-main-card-title");
    let i=0;
    let interval=setInterval(()=>{
      for(let j=0;j<processes.length;j++){
        processes[j].classList.remove("HowWeDoProcess-main-card-title-colored");
      }
      processes[i].classList.add("HowWeDoProcess-main-card-title-colored");
      i++;
      if(i>=processes.length)
      {
        i=0;
      }
        // if(i>=processes.length)
        // {
        //   for(let j=0;j<processes.length;j++){
        //     processes[j].classList.remove("HowWeDoProcess-main-card-title-colored");
        //   }
        //   i=0;
        // }
        // else{
        //   processes[i].classList.add("HowWeDoProcess-main-card-title-colored");
        //   i++;
        // }
        
        
        
    },2000)
    return ()=>{
      clearInterval(interval)
    }
  })

  return (
    <div className="HowWeDoProcess">
      <h1 className='HowWeDoProcess-header watch'>Our Process</h1>
      <h2 className='HowWeDoProcess-sub watch'>With well-established processes and deep knowledge proven by several successful projects in financial outsourcing, we will be a reliable partner that delivers on your goals flawlessly from start to finish.</h2>
      <div className='HowWeDoProcess-main watch'>
        <div className='HowWeDoProcess-main-card'>
          {/* <div className='HowWeDoProcess-main-card-number'>01</div> */}
          <div className='HowWeDoProcess-main-card-title '>Evaluate</div>
          <div className='HowWeDoProcess-main-card-desc '>All projects start by first assessing what exactly the client needs. If the requirements are not clear, then we guide the client to define them.</div>
        </div>
        <div className='HowWeDoProcess-main-card'>
          {/* <div className='HowWeDoProcess-main-card-number'>02</div> */}
          <div className='HowWeDoProcess-main-card-title '>Estimate</div>
          <div className='HowWeDoProcess-main-card-desc '>Based on requirements, we share our best proposal. Once it is approved, the client signs an engagement letter, adhering to the best standards.</div>
        </div>
        <div className='HowWeDoProcess-main-card'>
          {/* <div className='HowWeDoProcess-main-card-number'>03</div> */}
          <div className='HowWeDoProcess-main-card-title '>Onboard</div>
          <div className='HowWeDoProcess-main-card-desc '>Now that client has entered into a contract, we ask them to share the required documents, provide software access and prepare a workflow.</div>
        </div>
        <div className='HowWeDoProcess-main-card'>
          {/* <div className='HowWeDoProcess-main-card-number'>04</div> */}
          <div className='HowWeDoProcess-main-card-title '>Resource</div>
          <div className='HowWeDoProcess-main-card-desc '>Based on the client's requirements and availability of the resource, we allocate the best talent for the job.</div>
        </div>
        <div className='HowWeDoProcess-main-card'>
          {/* <div className='HowWeDoProcess-main-card-number'>05</div> */}
          <div className='HowWeDoProcess-main-card-title '>Execute</div>
          <div className='HowWeDoProcess-main-card-desc '>The assigned person or a team works on the project and submits their work to the team leader.</div>
        </div>
        <div className='HowWeDoProcess-main-card'>
          {/* <div className='HowWeDoProcess-main-card-number'>06</div> */}
          <div className='HowWeDoProcess-main-card-title '>Review</div>
          <div className='HowWeDoProcess-main-card-desc '>The team leader then reviews the task done, provides feedback, and performs final checks before delivering it to the client.</div>
        </div>
      </div>
    </div>
  );
}

export default HowWeDoProcess;
