import './Styles/CareerJobs.css';

import './Styles/Animation.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getFirestore,collection,getDocs,doc} from "firebase/firestore"; 
import experienceIcon from "./Resources/suitcase.svg";
import locationIcon from "./Resources/location.svg";
import positionsIcon from "./Resources/chair.svg";
import WFOIcon from "./Resources/laptop.svg";

function CareerJobs() {

  let [jobData,setJobData]=useState([]);
  let [filteredjobData,setFilteredJobData]=useState([]);

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{
    getJobData();
    Animate();
  },[]);
  useEffect(()=>{
    console.log("I am called");
    setFilteredJob("All")
  },[jobData])

  // useEffect(()=>{
  //   setFilteredJob();
  // },selectedCategory)


  const firebaseConfig = {
    apiKey: "AIzaSyDIO56H7sMsth2kvTPB5-a79M7o8IockkE",
    authDomain: "crossright-50fa6.firebaseapp.com",
    projectId: "crossright-50fa6",
    storageBucket: "crossright-50fa6.appspot.com",
    messagingSenderId: "986658181564",
    appId: "1:986658181564:web:7ae35c1c477168cdd9dbb9",
    measurementId: "G-Q797J9D3QG"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  async function getJobData(){
    let dataArr=[];
    const querySnapshot = await getDocs(collection(db, "jobPostings"));
    querySnapshot.forEach((doc) => {
      dataArr.push({...doc.data(),"id":doc.id});
    });
    setJobData(dataArr);
  }

  function setFilteredJob(category){
    if(category==="All"){
      setFilteredJobData(jobData)
    }
    else{
      let filtered=jobData.filter((value)=>{
        return value.category===category
      })
      setFilteredJobData(filtered);
    }

  }
  return (
    <div className="CareerJobs">
      <h1 className='CareerJobs-head watch'>Current Openings</h1>
      <div className='CareerJobs-main'>
        <h3>If you are a results-oriented person who is ready for challenges, check out what we've got for you.</h3>
      </div>
      <div className='CareerJobs-Jobs'>
        {jobData.length<=0 && "No Jobs found! Please check back later."}
        {jobData.length>0 && 
          <div>
            <div className='CareerJobs-Jobs-Categories'>

              <div className='CareerJobs-Jobs-Categories-Category CareerJobs-Jobs-Categories-Category-selected' 
              onClick={(e)=>{setFilteredJob("All");
                document.getElementsByClassName("CareerJobs-Jobs-Categories-Category-selected")[0].classList.remove("CareerJobs-Jobs-Categories-Category-selected");
                e.target.classList.add("CareerJobs-Jobs-Categories-Category-selected");
              }}>All</div>
              <div className='CareerJobs-Jobs-Categories-Category' onClick={(e)=>{setFilteredJob("Accounting and Bookkeeping");
                document.getElementsByClassName("CareerJobs-Jobs-Categories-Category-selected")[0].classList.remove("CareerJobs-Jobs-Categories-Category-selected");
                e.target.classList.add("CareerJobs-Jobs-Categories-Category-selected");}}>Accounting and Bookkeeping</div>
              <div className='CareerJobs-Jobs-Categories-Category' onClick={(e)=>{setFilteredJob("Taxation");
                document.getElementsByClassName("CareerJobs-Jobs-Categories-Category-selected")[0].classList.remove("CareerJobs-Jobs-Categories-Category-selected");
                e.target.classList.add("CareerJobs-Jobs-Categories-Category-selected");}}>Taxation</div>
              <div className='CareerJobs-Jobs-Categories-Category' onClick={(e)=>{setFilteredJob("Audit and Assurance");
                document.getElementsByClassName("CareerJobs-Jobs-Categories-Category-selected")[0].classList.remove("CareerJobs-Jobs-Categories-Category-selected");
                e.target.classList.add("CareerJobs-Jobs-Categories-Category-selected");}}>Audit and Assurance</div>
              <div className='CareerJobs-Jobs-Categories-Category' onClick={(e)=>{setFilteredJob("Business Development / CRM");
                document.getElementsByClassName("CareerJobs-Jobs-Categories-Category-selected")[0].classList.remove("CareerJobs-Jobs-Categories-Category-selected");
                e.target.classList.add("CareerJobs-Jobs-Categories-Category-selected");}}>Business Development / CRM</div>
              <div className='CareerJobs-Jobs-Categories-Category' onClick={(e)=>{setFilteredJob("Others");
                document.getElementsByClassName("CareerJobs-Jobs-Categories-Category-selected")[0].classList.remove("CareerJobs-Jobs-Categories-Category-selected");
                e.target.classList.add("CareerJobs-Jobs-Categories-Category-selected");}}>Others</div>
            </div>

            <div className='CareerJobs-Jobs-Main'>
            {filteredjobData.map((value,key)=>{
          return(
            value.jobStatus && 
            <div className='CareerJobs-Jobs-Main-Job' key={key}>
                
                <div className='CareerJobs-Jobs-Main-Job-Title'>
                <div className='CareerJobs-Jobs-Main-Job-Title-PostedDate'>
                  Posted on {value.postedDate}
                  </div>
                  {value.jobTitle}
                </div>
                <div className='CareerJobs-Jobs-Main-Job-Details'>
                  <div className='CareerJobs-Jobs-Main-Job-Details-Detail'>
                    <img src={experienceIcon} alt="Experience"/>
                    <p>{value.expRequired}+ Years</p>
                  </div>
                  <div className='CareerJobs-Jobs-Main-Job-Details-Detail'>
                    <img src={positionsIcon} alt="Positions"/>
                    <p>{value.noOfPositions} Openings</p>
                  </div>
                  <div className='CareerJobs-Jobs-Main-Job-Details-Detail'>
                    <img src={locationIcon} alt="Location"/>
                    <p>{value.location}</p>
                  </div>
                  <div className='CareerJobs-Jobs-Main-Job-Details-Detail'>
                    <img src={WFOIcon} alt="WFO"/>
                    <p>{value.WFO===true?"Work From Office":"Work From Home"}</p>
                  </div>
                </div>

                <a href={value.googleFormLink} className='CareerJobs-Jobs-Main-Job-Apply' target="_blank" rel="noreferrer">Apply</a>
              </div>
           
          )})}
          {filteredjobData.length<=0 && <div>No Jobs with this filter.</div>}

              
            </div>
            
          </div>
        }
      </div>
    </div>
  );
}

export default CareerJobs;
