import ServicesIcon from './ServicesIcon';
import './Styles/Services.css';
import './Styles/Animation.css';
import accountingSVG from "./Resources/accounting.svg";
import audit from "./Resources/audit.svg";
import payroll from "./Resources/payroll.svg";
import staffing from "./Resources/staffing.svg";
import tax from "./Resources/tax.svg";
import accounts from "./Resources/Accounts Management.svg";
import accountingService from "./Resources/accounting-service.svg";
import taxService from "./Resources/tax-service.png";
import bookkeepingService from "./Resources/Bookkeeping-service.svg";
import auditService from "./Resources/Audit-service.svg";
import remoteService from "./Resources/Remote-service.svg";
import payrollService from "./Resources/payroll-service.png";
import { useEffect, useState } from 'react';

function Services() {
  
  const [intervalId,setIntervalId]=useState(null);
  const [serviceData,setServiceData]=useState(
    <>Management Accounting provides crucial financial insights that enable informed, data-based decision-making for a business. And that’s why you need experts who furnish numbers with pinpoint accuracy.<br/><br/>
      We provide tailored solutions for management accounting to CPA and Accounting Firms across the United States and other countries.</>
  )
  const [svgIcon,setSvgIcon]=useState(accountingService);


function Animate(){
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // It's visible. Add the animation class here!
        if(entry.target.classList.contains("watch2")){
          entry.target.classList.add("inView2");
        }
        else if(entry.target.classList.contains("watch")){
          entry.target.classList.add("inView");
        }
        
      }
      else{
        entry.target.classList.remove("inView");
        entry.target.classList.remove("inView2");
      }
    });
  });
  
  // Tell the observer which elements to track
  let elementsToWatch=document.querySelectorAll('.watch');
  elementsToWatch.forEach(element=>{
    observer.observe(element);
  })
  let elementsToWatch2=document.querySelectorAll('.watch2');
  elementsToWatch2.forEach(element=>{
    observer.observe(element);
  })

}



  function changeActiveService(){
    let allServices=document.getElementsByClassName("Services-selector-select");
    for(let i=0;i<allServices.length;i++){
      if(allServices[i].classList.contains("Services-selector-select-active")){
        allServices[i].classList.remove("Services-selector-select-active")
      }
        
    }
  }

  function changeSlide(text,svgIconTemp){
    document.getElementsByClassName("content")[0].classList.remove("show");
    document.getElementsByClassName("content")[0].classList.add("fadeOut");
    document.getElementsByClassName("content-svg")[0].classList.remove("showSvg");
    document.getElementsByClassName("content-svg")[0].classList.add("hideSvg");
    setTimeout(()=>{
      setServiceData(text);
      setSvgIcon(svgIconTemp)
      document.getElementsByClassName("content")[0].classList.remove("fadeOut");
      document.getElementsByClassName("content")[0].classList.add("show");
      document.getElementsByClassName("content-svg")[0].classList.remove("hideSvg");
      document.getElementsByClassName("content-svg")[0].classList.add("showSvg");
    },500)
    
  }



  useEffect(()=>{
   
    let slides=document.getElementsByClassName("Services-selector-select");
    let i=1;
    let slideChange=setInterval(()=>{
        slides[i].click();
        i++;
        if(i>=slides.length){
          i=0;
        }
    },5000);
    setIntervalId(slideChange);
    Animate();
    return ()=>{
      clearInterval(slideChange);
    }
    
  },[])

  return (
    <>
    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#023062" fill-opacity="1" d="M0,160L60,165.3C120,171,240,181,360,197.3C480,213,600,235,720,234.7C840,235,960,213,1080,208C1200,203,1320,213,1380,218.7L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
</svg> */}
{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#023062" fill-opacity="1" d="M0,32L48,53.3C96,75,192,117,288,128C384,139,480,117,576,112C672,107,768,117,864,144C960,171,1056,213,1152,213.3C1248,213,1344,171,1392,149.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg> */}
    {/* <div className="Services">
        <h1>Services</h1>
        <div className='Services-main'>
          
          <div className='Services-main-service'>
            <ServicesIcon rotatingText="Management * Accounts * " iconName={accounts} CustomWordSpace="40px"/>
          </div>
          <div className='Services-main-service'>
            <ServicesIcon rotatingText="Tax * Return * Preparation * " iconName={tax} CustomWordSpace="20px"/>
          </div>
          <div className='Services-main-service'>
            <ServicesIcon rotatingText="Accounting * and * Bookkeeping * " iconName={accountingSVG} CustomWordSpace="0px"/>
          </div>
          <div className='Services-main-service'>
            <ServicesIcon rotatingText="Audit * and * Assurance * " iconName={audit} CustomWordSpace="25px"/>
          </div>
          <div className='Services-main-service'>
            <ServicesIcon rotatingText="Remote * Staffing * " iconName={staffing}  CustomWordSpace="50px"/>
          </div>
          <div className='Services-main-service'>
            <ServicesIcon rotatingText="Payroll * Processing * " iconName={payroll} CustomWordSpace="55px"/>
          </div>
        </div>
    </div> */}

      <div className="Services" >
      <h1 className='watch'>Services</h1>
        <div className='Services-main'>
          <div className='Services-selector' onMouseEnter={()=>{
        clearInterval(intervalId);
      }}>
         
         <div className='Services-selector-select blue' onClick={(e)=>{
              changeSlide(<>Bookkeeping and accounting are foundational aspects of any business, but at the same time consume a considerable bandwidth. We can lend you a helping hand here.<br/><br/>
              We manage and effectively handle multiple accounting and banking from diverse industries systematically and productively.</>,bookkeepingService)
              
              changeActiveService();
              e.target.classList.add("Services-selector-select-active")
            }}>
            <img src={accountingSVG} alt="accounting"/>
                Accounts & Bookkeeping
            </div>
            
            <div className='Services-selector-select  Services-selector-select-active purple' onClick={(e)=>{
              changeSlide(<>Management Accounting provides crucial financial insights that enable informed, data-based decision-making for a business. And that’s why you need experts who furnish numbers with pinpoint accuracy.<br/><br/>
              We provide tailored solutions for management accounting to CPA and Accounting Firms across the United States and other countries.</>,accountingService)
              changeActiveService();
              e.target.classList.add("Services-selector-select-active")
            }}>
              <img src={accounts} alt="accounts"/>
                Management Accounts
            </div>
            
            <div className='Services-selector-select blue' onClick={(e)=>{
              changeSlide(<>Taxes are complicated and tax professionals must know the details, the latest updates, and all deductions available for tax planning. And, we combine all of that with technical software skills to make the most of it.<br/><br/>
              Whether it be US income tax returns, partnership tax returns, corporate tax returns, payroll tax returns, or any diverse tax locales, we have got your back with taxation experts on our side.</>,taxService)
              changeActiveService();
              e.target.classList.add("Services-selector-select-active")
            }}>
            <img src={tax} alt="tax"/>
                Tax Return Preparation
            </div>

            <div className='Services-selector-select green' onClick={(e)=>{
              changeSlide(<>An audit is a high-stakes, time-consuming and resource-intensive task. Only qualified and experienced professionals can vouch for the authenticity, relevance, and quality of a client’s financial statements. Hence, it needs to be undertaken with utmost diligence.<br/><br/>
              We provide a comprehensive assessment of financial records to gauge the fair play of a business and successfully avoid any fraud or discrepancies for better stakeholder relationships.</>,auditService)
              
              changeActiveService();
              e.target.classList.add("Services-selector-select-active")
            }}>
            <img src={audit} alt="audit"/>
                Audit and Assurance
            </div>
            <div className='Services-selector-select maati' onClick={(e)=>{
              changeSlide(<>Remote staffing is now a preferred mode of hiring professionals as it reduces personnel and infrastructure costs. But to make it successful, you need to hire the right people for the job. Finding the right talent is difficult and that’s where we come in.<br/><br/>
              We can be your extended team to manage all things accounting and finance so that you can focus on scaling up your business. </>,remoteService)
            
              changeActiveService();
              e.target.classList.add("Services-selector-select-active")
            }}>
            <img src={staffing} alt="staffing"/>
                Remote Staffing
            </div>
            <div className='Services-selector-select red' onClick={(e)=>{
              changeSlide(<>Payroll though seems simple, is in fact complicated. Payroll administrators have to take care of compliance, taxation, accuracy, and confidentiality. Even a small mistake can prove extremely expensive.<br/> <br/>
              We are adept at payroll management, ensure full compliance with wages and labor act, and hence can serve your client’s needs with full transparency.</>,payrollService)
            
              changeActiveService();
              e.target.classList.add("Services-selector-select-active")
              
            }}>
            <img src={payroll} alt="payroll"/>
                Payroll Processing
            </div>
          </div>
          <div className='Services-main-desc watch'>
            <div className='Services-main-desc-text'>
              <div className='content'>
                <div className='content-data'>
                {serviceData}
                </div>
                
                <div className='content-svg'>
                  <img src={svgIcon} alt="svg"/>
                </div>
              </div>
              
            </div>
          
          </div>
        </div>
        
      </div>
    
    </>
    
  );
}

export default Services;
