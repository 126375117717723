import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import About from './About';
import HowWeDo from './HowWeDo';
import Service from './Service';
import Career from './Career';
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';
import TermsAndConditions from './TermsAndConditions';

import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Pricing from './Pricing';
import Privacy from './Privacy';





const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/About",
    element: <About />,
  },
  {
    path: "/HowWeDo",
    element: <HowWeDo />,
  },
  {
    path: "/Services",
    element: <Service />,
  },
  {
    path: "/Career",
    element: <Career />,
  },
  {
    path: "/Pricing",
    element: <Pricing />,
  },
  {
    path: "/AdminPanel",
    element: <AdminLogin />,
  },
  {
    path: "/AdminPanelDashboard",
    element: <AdminPanel />,
  },
  {
    path: "/TermsOfServices",
    element: <TermsAndConditions />,
  },
  {
    path: "/Privacy",
    element: <Privacy />,
  },
  {
    path: "*",
    element: <><h1 style={{"margin":'10vw auto',"textAlign":"center","color":"#027FDC"}}>Looks like this page doesn't exist</h1></>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
