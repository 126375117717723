import './Styles/PayModel.css';
import './Styles/Animation.css';
import paySVG from "./Resources/payasyougo.svg";
import fixedSVG from "./Resources/fixed.svg";
import sharingSVG from "./Resources/sharing.svg";
// import tickLight from "./Resources/tick5.svg";
import tickLight from "./Resources/unfilledwhite4.svg";
import tick from "./Resources/dot-small.svg";
import { useEffect } from 'react';
// import tick from "./Resources/tick.svg";


function PayModel() {
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate()},[])
  return (
    <>
    
    <div className="PayModel">
        <h1 className='watch'>Our Engagement Models</h1>
        <div className='models'>
          <div className='model'>
          <div className="model-display-card">
          <img className='watch' src={paySVG} alt="Pay As You Go" />
            <h2 className='watch'>Pay-As-You-Go</h2>
            <p className='watch'>
            No need to write checks or remember payments, just pay for the services availed.
            </p>
          </div>
            
            <div>
              <ul>
                <li className='watch'>
                   No long-term contract
                </li>
                <li className='watch'>
                  Pay only for the hours we work
                </li>
                <li className='watch'>
                  Task-based pricing
                </li>
                <li className='watch'>
                  Monthly/weekly billing
                </li>
                <li className='watch'>
                  Quick follow-up and query handling
                </li>
              </ul>
             
            </div>
          </div>
          <div className='model'>
            <div className="model-display-card">
              <img className='watch' src={fixedSVG} alt="Fixed" />
              <h2 className='watch'>(Popular) <br/>
              Fixed Resource</h2>
              <p className='watch'>
              Hire a dedicated expert that works just like an in-house employee.
              </p>
            </div>
            
            <div>
            <ul >
                <li className='watch'>
                Professionals that work exclusively on your project
                </li>
                <li className='watch'>
                Fixed billing rate
                </li>
                <li className='watch'>
                Monthly/weekly billing
                </li>
                <li className='watch'>
                Suitable for companies with a lot of work to outsource
                </li>
                <li className='watch'>
                  
                Quick turnaround time
                </li>
                <li className='watch'>
                Daily/weekly timesheet reporting
                </li>
              </ul>
              {/* <div className="cards">
                  <div className="card">
                    Contract based
                  </div>
                  <div className="card">
                     Quick turnaround time
                  </div>
                  <div className="card">
                    Flexibility in no. of hours
                  </div>

                  <div className="card">
                    Shared payment
                  </div>
                  <div className="card">
                    Daily/weekly timesheet reporting
                  </div>
                  <div className="card">
                    Amount of work
                  </div>
                  <div className="card">
                    Monthly/Weekly billing
                  </div>
                  <div className="card">
                    Dedicated Resource
                  </div>
              </div> */}
            </div>
          </div>
          <div className='model'>
          <div className="model-display-card">
          <img className='watch' src={sharingSVG} alt="Sharing" />
            <h2 className='watch'>Revenue Sharing</h2>
            <p className='watch'>
            Let us be your resource and business partner.
            </p>
            </div>
            
            <div>
            <ul >
                <li className='watch'>
                Profit-sharing based on efforts
                </li>
                <li className='watch'>
                No hassles of timesheet reporting
                </li>
                <li className='watch'>
                Lumpsum payment at the start of project
                </li>
                <li className='watch'>
                Beneficial for all types of organizations
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="comparision ">
          <div className="col ">
              <div className='row watch'>
                
              </div>
              <div className='row watch'>
                Contract based
              </div>
              <div className='row watch'>
              Quick turnaround time
              </div>
              <div className='row watch'>
              Flexibility in no. of hours
              </div>
              <div className='row watch'>
              Daily/weekly timesheet reporting
              </div>
              <div className='row watch'>
              Amount of work
              </div>
              <div className='row watch'>
              Shared payment
              </div>
              <div className='row watch'>
              Monthly/Weekly billing
              </div>
              <div className='row watch'>
              Dedicated Resource
              </div>
          </div>
          <div className="col">
              <div className='row watch'>
                Pay As You Go
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch' >
                Low to Medium
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
          </div>
          <div className="col">
              <div className='row watch'>
                Fixed Resource
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                High
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
          </div>
          <div className="col">
              <div className='row watch'>
                Revenue Sharing
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
              <div className='row watch'>
                Medium to High
              </div>
              <div className='row watch'>
                <img src={tick} alt="Yes" />
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
              <div className='row watch'>
                <img src={tickLight} alt="No" />
              </div>
          </div>
        </div>
    </div>
    
    </>
    
  );
}

export default PayModel;
