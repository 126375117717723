import './Styles/HowWeDoCountries.css';
import './Styles/Animation.css';
import usa from "./Resources/usa.svg"
import canada from "./Resources/canada.svg"
import australia from "./Resources/australia.svg"
import india from "./Resources/india.svg"
import { useEffect } from 'react';

function HowWeDoCountries() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="HowWeDoCountries">
      <h1 className='HowWeDoCountries-header watch'>Countries We Serve</h1>
      <div className='HowWeDoCountries-main'>
        <div className='HowWeDoCountries-main-card'>
          <div className='HowWeDoCountries-main-card-img watch'>
            <img src={usa} alt="USA"/>
          </div>
          <div className='HowWeDoCountries-main-card-title watch'>
            USA
          </div>
        </div>
        <div className='HowWeDoCountries-main-card'>
          <div className='HowWeDoCountries-main-card-img watch'>
            <img src={canada} alt="USA"/>
          </div>
          <div className='HowWeDoCountries-main-card-title watch'>
            Canada
          </div>
        </div>
        <div className='HowWeDoCountries-main-card'>
          <div className='HowWeDoCountries-main-card-img watch'>
            <img src={australia} alt="USA"/>
          </div>
          <div className='HowWeDoCountries-main-card-title watch'>
            Australia
          </div>
        </div>
        <div className='HowWeDoCountries-main-card'>
          <div className='HowWeDoCountries-main-card-img watch'>
            <img src={india} alt="USA"/>
          </div>
          <div className='HowWeDoCountries-main-card-title watch'>
            India
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowWeDoCountries;
