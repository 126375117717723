import './Styles/Navbar2.css';
import call from './Resources/call 3.svg';
import mail from './Resources/mail.svg';
import linkedin from './Resources/linkedin.svg';
import brochure from './Resources/brochure.png';
import usaBrochure from './Resources/brochure USA.pdf';
import canadaBrochure from './Resources/canadaBrochure.pdf';
import {useNavigate } from "react-router-dom";
import LogoReveal3 from './LogoReveal3';


import accountingSVG from "./Resources/accounting.svg";
import audit from "./Resources/audit.svg";
import payroll from "./Resources/payroll.svg";
import staffing from "./Resources/staffing.svg";
import tax from "./Resources/tax.svg";
import accounts from "./Resources/Accounts Management.svg";
function Navbar2(props) {
  const navigate=useNavigate();

  function toggleMenu(){
    if(document.getElementsByClassName("Navbar2-Mobile-menu")[0].classList.contains("Navbar2-Mobile-menu-open")){
      document.getElementsByClassName("Navbar2-Mobile-menu")[0].classList.remove("Navbar2-Mobile-menu-open")
      document.getElementsByClassName("Navbar2-Mobile-menu-items")[0].classList.remove("Navbar2-Mobile-menu-items-open")
    }
    else{
      document.getElementsByClassName("Navbar2-Mobile-menu")[0].classList.add("Navbar2-Mobile-menu-open")
      document.getElementsByClassName("Navbar2-Mobile-menu-items")[0].classList.add("Navbar2-Mobile-menu-items-open")
    }
  }
  return (
    <>
    <div className="Navbar2">
      {/* {noAnimation===true && <LogoReveal3 /> }
      {noAnimation===false && <LogoReveal2 />} */}
      <LogoReveal3/>
      <div className='Navbar2-menu-items'>
        <div className='Navbar2-menu-items-menu'>
          <div className='Navbar2-menu-items-menu-item' onClick={()=>{
             navigate("/About");
          }}>About Us</div>
          <div className='Navbar2-menu-items-menu-item  Navbar2-menu-items-menu-item-Services' onClick={()=>{
            localStorage.setItem('service', "1");
            navigate("/Services");
          }}>
            Services
           
          </div>
          <div className='Navbar2-Services-submenu'>
              <div className='Navbar2-Services-submenu-brochure'>
                <img src={brochure} alt="Brochure" />
                <div className='Navbar2-Services-submenu-brochure-btn-group'><a href={usaBrochure} download><button>USA</button></a><a href={canadaBrochure} download><button>Canada</button></a></div>
                <span>Download Brochure</span>
              </div>
              <div className='Navbar2-Services-submenu-services'>
                <div className='Navbar2-Services-submenu-services-group'>
                
                <div className='Navbar2-Services-submenu-services-service'   onClick={()=>{
            localStorage.setItem('service', "3");
            navigate("/Services");
          }}>
                <div className='Navbar2-Services-submenu-services-service-Icon'> <img src={accountingSVG} alt="accounts"/></div>
                  <div className='Navbar2-Services-submenu-services-service-rest'>
                    <div className='Navbar2-Services-submenu-services-service-rest-Name'>Accounting & Bookkeeping</div>
                    <div className='Navbar2-Services-submenu-services-service-rest-Tagline'>Accurate Accounting & Bookkeeping is the Foundation</div>
                  </div>
                  
                </div>
                <div className='Navbar2-Services-submenu-services-service'  onClick={()=>{
            localStorage.setItem('service', "4");
            navigate("/Services");
          }}>
            <div className='Navbar2-Services-submenu-services-service-Icon'> <img src={audit} alt="accounts"/></div>
                  <div className='Navbar2-Services-submenu-services-service-rest'>
                    <div className='Navbar2-Services-submenu-services-service-rest-Name'>Audit & Assurance</div>
                    <div className='Navbar2-Services-submenu-services-service-rest-Tagline'>Auditing is at the Pinnacle of Accounting</div>
                  </div>
                  
                </div>
                <div className='Navbar2-Services-submenu-services-service'  onClick={()=>{
            localStorage.setItem('service', "1");
            navigate("/Services");
          }}>
                  <div className='Navbar2-Services-submenu-services-service-Icon'> <img src={accounts} alt="accounts"/></div>
                  <div className='Navbar2-Services-submenu-services-service-rest'>
                    <div className='Navbar2-Services-submenu-services-service-rest-Name'>Management Accounting</div>
                    <div className='Navbar2-Services-submenu-services-service-rest-Tagline'>Management Accounting equals informed decision</div>
                  </div>
                 
                  
                </div>
                </div>
                <div className='Navbar2-Services-submenu-services-group'>
                <div className='Navbar2-Services-submenu-services-service'   onClick={()=>{
            localStorage.setItem('service', "2");
            navigate("/Services");
          }}>
             <div className='Navbar2-Services-submenu-services-service-Icon'> <img src={tax} alt="accounts"/></div>
                  <div className='Navbar2-Services-submenu-services-service-rest'>
                    <div className='Navbar2-Services-submenu-services-service-rest-Name'>Tax Return Preparation</div>
                    <div className='Navbar2-Services-submenu-services-service-rest-Tagline'>Taxation is Complex, We Make it Simple for You</div>
                  </div>
                  
                </div>
                <div className='Navbar2-Services-submenu-services-service'  onClick={()=>{
            localStorage.setItem('service', "5");
            navigate("/Services");
          }}>
            <div className='Navbar2-Services-submenu-services-service-Icon'> <img src={staffing} alt="accounts"/></div>
                  <div className='Navbar2-Services-submenu-services-service-rest'>
                    <div className='Navbar2-Services-submenu-services-service-rest-Name'>Remote Staffing</div>
                    <div className='Navbar2-Services-submenu-services-service-rest-Tagline'>Best Outsourced Finance Professionals On Demand</div>
                  </div>
                  
                </div>
                
                <div className='Navbar2-Services-submenu-services-service'  onClick={()=>{
            localStorage.setItem('service', "6");
            navigate("/Services");
          }}>
                  
                  <div className='Navbar2-Services-submenu-services-service-Icon'> <img src={payroll} alt="accounts"/></div>
                  <div className='Navbar2-Services-submenu-services-service-rest'>
                    <div className='Navbar2-Services-submenu-services-service-rest-Name'>Payroll Processing</div>
                    <div className='Navbar2-Services-submenu-services-service-rest-Tagline'>Better Payroll Management For Highest Employee Morale</div>
                  </div>
                </div>
                </div>
                
                
              </div>

          </div>
          <div className='Navbar2-menu-items-menu-item' onClick={()=>{
             navigate("/HowWeDo");
          }}>How we do</div>
          <div className='Navbar2-menu-items-menu-item' onClick={()=>{
             navigate("/Pricing");
          }}>Pricing</div>
          
        </div>
        
        <div className='Navbar2-menu-items-contact'>
        <div className='Navbar2-menu-items-contact-item'>
          <div className='Navbar2-contact'>
            <div className='Navbar2-contact-item'>
              <a target="_blank" href='tel:+19564350952'><img src={call} alt="call"/></a>
              <div className='tooltip'>+1(956)-435-0952</div>
            </div>
            <div className='Navbar2-contact-item'>
              <a target="_blank" href='mailto:info@crossright.in'><img src={mail} alt="call"/></a>
              <div className='tooltip'>info@crossright.in</div>
            </div>
            <div className='Navbar2-contact-item'><a target="_blank" href='https://www.linkedin.com/company/crossright/'><img src={linkedin} alt="social"/></a></div>
          </div>
        </div>
        </div>
        
      
      </div>

      <button className='Navbar2-career' onClick={()=>{
             navigate("/Career");
          }}>Join our Team</button>
      
    </div>
    <div className="Navbar2-Mobile">
      {/* {noAnimation===true && <LogoReveal3 /> }
      {noAnimation===false && <LogoReveal2 />} */}
      <LogoReveal3  className="logo-comp"/>
      <div className='Navbar2-Mobile-menu' onClick={toggleMenu}>
        <div></div>
        <div></div>
      </div>
      <div className='Navbar2-Mobile-menu-items'>
        <div className='Navbar2-Mobile-menu-items-menu'>
          <div className='Navbar2-Mobile-menu-items-menu-item' onClick={()=>{
             navigate("/About");
          }}>About Us</div>
          <div className='Navbar2-Mobile-menu-items-menu-item' onClick={()=>{
             navigate("/Services");
          }}>Services</div>
          <div className='Navbar2-Mobile-menu-items-menu-item' onClick={()=>{
             navigate("/HowWeDo");
          }}>How we do</div>
          <div className='Navbar2-Mobile-menu-items-menu-item' onClick={()=>{
             navigate("/Pricing");
          }}>Pricing</div>
          <div className='Navbar2-Mobile-menu-items-menu-item' onClick={()=>{
             navigate("/Career");
          }}>Join our Team</div>
        </div>
        <div className='Navbar2-Mobile-menu-items-contact'>
        <div className='Navbar2-Mobile-menu-items-contact-item'>
          <div className='Navbar2-Mobile-contact'>
            <div className='Navbar2-Mobile-contact-item'><a target="_blank" href='tel:+19564350952'><img src={call} alt="call"/></a></div>
            <div className='Navbar2-Mobile-contact-item'><a target="_blank" href='mailto:info@crossright.in'><img src={mail} alt="call"/></a></div>
            <div className='Navbar2-Mobile-contact-item'><a target="_blank" href='https://www.linkedin.com/company/crossright/'><img src={linkedin} alt="social"/></a></div>
          </div>
        </div>
        </div>
        
      
      </div>
      
      
    </div>
    </>
    
  );
}

export default Navbar2;
