import './Styles/AdminLogin.css';
import './Styles/Animation.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth,signInWithEmailAndPassword } from "firebase/auth";
import {useNavigate } from "react-router-dom";
import { useEffect } from 'react';
function AdminLogin() {

  const navigate=useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyDIO56H7sMsth2kvTPB5-a79M7o8IockkE",
    authDomain: "crossright-50fa6.firebaseapp.com",
    projectId: "crossright-50fa6",
    storageBucket: "crossright-50fa6.appspot.com",
    messagingSenderId: "986658181564",
    appId: "1:986658181564:web:7ae35c1c477168cdd9dbb9",
    measurementId: "G-Q797J9D3QG"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);

  

  async function verifyLogin(){
    let username=document.getElementById("username").value
    let password=document.getElementById("password").value
    console.log(username,password);
      signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        sessionStorage.setItem("loggedIn", true);
        navigate("/AdminPanelDashboard");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(`Incorrect credentials ${errorMessage}`)
      });
  }

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="AdminLogin">
      <h1>Crossright Admin</h1>
      <input id='username' className='AdminLogin-input' type="text" placeholder="Username" />
      <input id='password' className='AdminLogin-input' type="password" placeholder="Password" />
      <button onClick={verifyLogin} className='AdminLogin-input AdminLogin-input-btn'>Login</button>
    </div>
  );
}

export default AdminLogin;
