import './Styles/PricingHero.css';
import './Styles/Animation.css';
import pricingpic from './Resources/pricingpic.png';
import { useEffect } from 'react';


function PricingHero(props) {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  

  

  return (
    <div className="PricingHero">
      <div className='PricingHero-main'>
        <h1 className='PricingHero-main-header watch2'>On-demand<span> finance professionals</span> with flexible engagement models</h1>
        <h3 className='PricingHero-main-header-sub watch2'>
         Hire CPA, CA, Accounting, Taxation, and Payroll Experts as per Your Needs
        </h3>
        <button onClick={()=>{
          document.getElementsByClassName('PricingDialog')[0].scrollIntoView();
        }} className='PricingHero-main-action watch2'>Unlock Price</button>
      </div>
      <div className='chart-svg watch2'>
        <img src={pricingpic} alt="how we do" />
      </div>
    </div>
  );
}

export default PricingHero;
