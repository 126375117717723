import './Styles/HoveringBar.css';
import './Styles/Animation.css';
import calendar from './Resources/calendar.svg';
import logo from './Resources/logo2.png'

import {useNavigate } from "react-router-dom";
function HoveringBar() {
  const navigate=useNavigate();
  return (
    <div className="HoveringBar">
      <div className='HoveringBar-Logo' onClick={()=>{
             navigate("/");
          }}>
        <img  src={logo} alt="logo" />
      </div>
      <div className='HoveringBar-Links'>
      <div className='HoveringBar-Links-item' onClick={()=>{
             navigate("/About");
          }}>About Us</div>
          <div className='HoveringBar-Links-item' onClick={()=>{
            localStorage.setItem('service', "1");
            navigate("/Services");
          }}>Services</div>
          <div className='HoveringBar-Links-item' onClick={()=>{
             navigate("/HowWeDo");
          }}>How we do</div>
          <div className='HoveringBar-Links-item' onClick={()=>{
             navigate("/Pricing");
          }}>Pricing</div>
          {/* <div className='HoveringBar-Links-item' onClick={()=>{
             navigate("/Career");
          }}>Join our Team</div> */}
      </div>
      <a target="_blank" rel="noreferrer" className='HoveringBar-Calendar' href='https://calendly.com/crossright/'>
          <a href='https://calendly.com/crossright/' rel="noreferrer" target="_blank"><img src={calendar} alt="Schedule" /></a>
          <a href='https://calendly.com/crossright/' rel="noreferrer" target="_blank"><h3>Schedule Meeting</h3></a>
      </a>
    </div>
  );
}

export default HoveringBar;
