import { useEffect } from 'react';
import './Styles/AboutValues.css';

import './Styles/Animation.css';

function AboutValues() {

  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="AboutValues">
      <h1 className='watch'>
      Values That Define Us
      </h1>
      <div className='AboutValues-main'>
        <h3 className='watch'>We integrate our financial excellence with human values that drive our business and our approach to relationships.</h3>
        <div className='AboutValues-main-card-group'>
          <div className='AboutValues-main-card'>
            <div className='AboutValues-main-card-number watch'>01</div>
            <div className='AboutValues-main-card-title watch'>
              Ownership
            </div>
            <div className='AboutValues-main-card-content watch'>
              We connect with people whose passion drives ownership and deliver excellence.
            </div>
          </div>
          <div className='AboutValues-main-card'>
          <div className='AboutValues-main-card-number watch'>02</div>
            <div className='AboutValues-main-card-title watch'>
            Inclusion
            </div>
            <div className='AboutValues-main-card-content watch'>
            We’re committed to cultivating an inclusive workplace that is free from any bias. Everybody can contribute to the betterment of each other.
            </div>
          </div>
          <div className='AboutValues-main-card'>
          <div className='AboutValues-main-card-number watch'>03</div>
            <div className='AboutValues-main-card-title watch'>
            Cultural Diversity
            </div>
            <div className='AboutValues-main-card-content watch'>
            Everyone’s welcome here. There is zero tolerance for discrimination based on caste, religion, gender, or ethnicity. 
            </div>
          </div>
          <div className='AboutValues-main-card'>
            <div className='AboutValues-main-card-number watch'>04</div>
            <div className='AboutValues-main-card-title watch'>
            Integrity
            </div>
            <div className='AboutValues-main-card-content watch'>
            Accountability and being true to ourselves in our work builds reliability, and we sustain this virtue.
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  );
}

export default AboutValues;
