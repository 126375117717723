import { useEffect } from 'react';
import './Styles/AboutStory.css';

import './Styles/Animation.css';

function AboutStory() {

  

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  

  return (
    <div className="AboutStory">
      <h1 className='watch'>
        Our Story
      </h1>
      <div className='AboutStory-card'>
      <div className='AboutStory-card-column watch'>
      One fine day, three founders joined hands to create solutions in the world of financial advisory and make it impactful. Our mission was clear - to make a difference and help businesses succeed.
      <br/><br/>
      We, with extensive financial advisory experience in multiple countries, took the initiative to build a financial outsourcing business that would transform the way companies manage their finances. 
      <br/><br/>
      In a short span, we nurtured our team from its humble beginnings to a remarkable size of talented individuals.
      
      </div>
      <div className='AboutStory-card-column watch'>
      
      We share our deep passion for empowering businesses. We celebrated our clients' dreams turn into reality and lasting impacts being created. We stood out in the industry due to our commitment to excellence and our ability to simplify complex financial matters.
      <br/><br/>
      Welcome to our world of CrossRight, building <span>RIGHT</span> connections a<span>CROSS</span> countries. 
      </div>
      {/* <div className='AboutStory-card-column'>
      
      </div> */}
      










      </div>
    </div>
  );
}

export default AboutStory;
