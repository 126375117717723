import './Styles/Privacy.css';
import './Styles/Animation.css';

import Navbar2 from './Navbar2';
import { useEffect, useState } from 'react';
import Footer from './Footer';
import PrivacyDetails from './PrivacyDetails';

function Privacy() {
  const [loaded,setLoaded]=useState(false);


  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }

  useEffect(()=>{
    Animate();
    setTimeout(()=>{
      setLoaded(true);
    },2000)
  })

  return (
    <>
      <div className="Service">
      <Navbar2 noAnimation={true}/>
      {
        loaded &&
        <>
          <PrivacyDetails />
          <Footer />
          
        </>
        
      }
      
    </div>
    
    </>
    
  );
}

export default Privacy;
