import './Styles/LogoReveal2.css';
import logo from "./Resources/final.gif"
import { useEffect, useState } from 'react';

function LogoReveal2() {
  const [gif, setGif] =useState('')
  const reloadGif = () => {
    setGif('')
    setTimeout(() => {
      setGif(logo)
    }, 0)
  }
  useEffect(()=>{
    reloadGif()
  },[])
  return (
    <div className="LogoReveal2">
      <img className='logo' id='gif' src={gif} alt="logo" />
    </div>
  );
}

export default LogoReveal2;
