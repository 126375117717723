import './Styles/TermsOfService.css';
import './Styles/Animation.css';
import { useEffect } from 'react';

function TermsOfService(props) {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
 
  return (
    <>
    <div className='Terms'>
      <h1 className='Terms-head watch'>Terms of Service</h1>
      <div className='Terms-update watch'>Last Updated: 10-06-2023</div>
      <ol>
        <li className='watch'>
          Scope of Services
          <ol>
            <li className='watch'>
            According to this Agreement, CrossRight Solutions (referred to as "CrossRight") agrees to provide the services as described in the Engagement Letter signed with the Customer.
            </li>
            <li className='watch'>
            CrossRight agrees to maintain the information, documentation, reports, and evidence as part of this Agreement in absolute confidence and not to disclose it to unauthorized third parties.
            </li>
          </ol>
        </li>
        <li className='watch'>
          Representations of CrossRight
          <ol>
            <li>
              CrossRight represents and warrants that:
              <ol type="i">
                <li className='watch'>
                CrossRight shall perform the Services in accordance with all laws, rules, and regulations applicable to it and its business;
                </li>
                <li className='watch'>
                the technicians or individuals appointed for provisioning of the Services have the required technical knowledge and methodological information;
                </li>
                <li className='watch'>
                the Services will be performed skillfully and professionally; and
                </li>
                <li className='watch'>
                it will not knowingly introduce any virus, malware, or other harmful computer code, files, scripts, or agents into the Customer's software or systems.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Confidentiality
          <ol>
            <li className='watch'>
            CrossRight agrees to keep confidential all data, technical, product, business, financial, and other information regarding the business and software programs of the Customer, its affiliates, customers, employees, investors, contractors, vendors and suppliers (the "Confidential Information"), including but not limited to programming techniques and methods, research and development, computer programs, documentation, marketing plans, customer identity, and business methods, except as may be necessary because of legal, accounting or regulatory requirements applicable to each party's business. Without limiting the generality of the foregoing, Confidential Information includes all information and materials disclosed orally or in any other form, regarding the Customer's and its affiliates' software products or software product development including, but not limited to, the configuration techniques, data classification techniques, user interface, applications programming interfaces, data modeling and management techniques, data structures, and other information of or relating to the Customer's and its affiliates' software products or derived from testing or other use thereof.

            </li>
            <li className='watch'>
            CrossRight shall at all times protect and safeguard the Confidential Information and agree not to disclose, transmit, or otherwise convey any Confidential Information, in whole or in part, to any unauthorized third party. CrossRight agrees that customers of the Customer are third party beneficiaries to all confidentiality-related obligations of CrossRight hereunder and have the right to enforce the relevant provisions in respect thereof.
            </li>
            <li className='watch'>
            The Customer accepts the use of email by CrossRight for all formal communication with the Customer and its personnel, including transferring data as an attachment to email messages. Whereas CrossRight will generally use email for the transmission of routine communication, the Customer understands that email is not a secure method for transferring data.
            </li>
            <li className='watch'>
            CrossRight agrees that it will not use any Confidential Information for its purpose or the benefit of any third party and shall honor the copyrights and other intellectual property rights of the Customer and will not copy, duplicate, or in any manner reproduce any such copyrighted materials.
            </li>
            <li className='watch'>
            Upon request of the Customer, CrossRight shall promptly deliver to the Customer all documents, notes, or other physical embodiments of or reflecting the Confidential Information (including copies thereof) that are in its possession or control.
            </li>
            <li className='watch'>
            Nothing in this Agreement will be construed as conveying to CrossRight any right, title, or interests or copyright in or to any Confidential Information of the Customer; or to convey any license as to use, sell, exploit, copy or further develop any such Confidential Information.
            </li>
            <li className='watch'>
            The provisions of this Section will survive the termination or expiration of this Agreement. The Customer has the right to take such action it deems necessary to protect its rights hereunder, including, without limitation, injunctive relief and any other remedies available at law or equity.
            </li>
          </ol>
        </li>
        <li className='watch'>
          Term <br/><br/>
          The term of this Agreement shall be for the Engagement Period's duration, as mentioned in the Engagement Letter from the Engagement Start Date.
        </li>
        <li className='watch'>
          Termination<br/><br/>
          A party may terminate this Agreement in the event of a breach, to the extent the breach is capable of being cured, if the breaching party does not rectify the breach within thirty (30) business days after receipt of written notice thereof, or if incapable of cure, immediately upon delivery of written notice.
        </li>
        <li className='watch'>
        Ownership<br/><br/>
        The Customer owns and reserves all rights, title, and interest in and to the Customer information, documentation, evidence, reports, and system provided, intellectual property rights and derivatives, modifications, refinements or improvements to that ("Intellectual Property"). Nothing contained in this Agreement shall grant CrossRight any express or implied rights or licenses concerning Intellectual Property or Confidential Information or any patent rights of the Customer.
        </li>
        <li className='watch'>
        Indemnification
        <br/><br/>
        The Customer agrees to indemnify CrossRight from certain risks inherent in projects of this nature. Specifically, the Customer agrees to indemnify and hold CrossRight harmless against and from all losses, claims, damages or liabilities to which CrossRight may become subject in connection with its report and other communication, under Indian law or otherwise. CrossRight shall not be indemnified to the extent of such losses, claims, damages, or liabilities resulting from CrossRight's bad faith, gross negligence, or willful misconduct (where the Customer itself was not guilty of bad faith, gross negligence or willful misconduct) in performing the services. In the event CrossRight is requested under subpoena or other legal processes to produce its documents relating to this engagement for the Customer in judicial or administrative proceedings to which CrossRight is not a party, the Customer shall reimburse CrossRight at standard billing in responding to such a request. This indemnification shall be limited to the amount of fees paid for the service.
        </li>
        <li className='watch'>
        Limitation of Liability
        <br/>
        <br/>
        The Customer agrees that the liability to the Customer of CrossRight, its Directors and employees (in contract or tort or under statute or otherwise) for any economic loss or damage suffered by the Customer arising out of or in connection with this work, however, the loss or damage is caused, shall be limited to the amount of fees paid to CrossRight. CrossRight accepts the benefit of this Agreement as agent and trustee for each of CrossRight Directors, employees, and agents.
        </li>
        <li className='watch'>
        Entire Agreement
        <br/><br/>
        This Agreement, along with the Engagement Letter, constitutes the entire Agreement between CrossRight and Customer. No modification to this Agreement shall be effective unless made in writing and signed by both parties. In the event of a conflict between the terms of this Agreement and the Engagement Letter, the terms of the Engagement Letter shall prevail unless expressly stated otherwise.
        </li>
        <li className='watch'>
        Relationship of Parties
        <br/><br/>
        With respect to one another, the parties are independent contractors for all purposes. There is no relationship of agency, partnership, joint venture, employment, or franchise between the parties. CrossRight has no authority to bind the Customer or to incur any obligations on the Customer's behalf. Except as otherwise outlined in this Agreement or the Engagement Letter, CrossRight is responsible for all costs and expenses in completing the Services and shall provide its supplies and equipment. CrossRight acknowledges and agrees that CrossRight is not entitled to any employee benefits of the Customer, including without limitation, group health insurance, workers' compensation insurance, unemployment compensation, disability, liability, or any other type of insurance.

        </li>
        <li className='watch'>
        Use of Reference
        <br/><br/>
        For marketing or publicizing or selling of CrossRight services, CrossRight may wish to disclose that CrossRight has performed work for the Customer. The Customer accepts and agrees to allow CrossRight to use the name and logo of the Customer as a reference in proposals and other similar submissions to clients or prospective clients of CrossRight, and for marketing or publicizing or selling CrossRight services

        </li>
        <li className='watch'>
        Severability
        <br/><br/>
        If any provision of this Agreement is held to be invalid or unenforceable under any statute or the rule of law, the provision to that extent to be deemed omitted and the remaining provisions shall not be affected in any way.
        </li>
        <li className='watch'>
        Assignment
        <br/><br/>
        This Agreement may not be assigned by CrossRight without the prior written consent of the Customer.
        </li>
        <li className='watch'>
        Governing Law
        <br/><br/>
        The laws of India govern this Agreement. The courts in Ahmedabad shall have the exclusive jurisdiction over the matters mentioned herein.
        </li>
        <li className='watch'>
        Force Majeure
        <br/><br/>
        CrossRight shall not be liable for any delay or failure of performance of any of its obligations under or arising out of this contract, if the failure or delay results from any act of God, refusal of permissions or other Government Act, fire, explosion, accident, industrial dispute and the like which renders it impossible or impracticable for CrossRight to fulfill its obligations under this Agreement or any other cause/circumstances of whatsoever nature beyond CrossRight's control. If the Force Majeure event lasts longer than thirty (30) days, then the Customer may immediately terminate, in whole or in part, this Agreement by giving written notice to CrossRight.
        </li>
        <li className='watch'>
        Anti-Corruption
        <br/><br/>
        Customer agrees that it has not received or been offered any illegal or improper bribe, kickback, payment, gift, or thing of value from any Secureframe employee or agent in connection with this Agreement. If Customer learns of any violation of the above restriction, Customer will promptly notify Secureframe.
        </li>
        <li className='watch'>
        Interpretation
        <br/><br/>
        For purposes hereof, “including” or “such as” means “including without limitation”.
        </li>
      </ol>
    </div>
    </>
    
  );
}

export default TermsOfService;
