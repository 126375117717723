import { useEffect } from 'react';
import './Styles/AboutHero.css';

import aboutPic from './Resources/AboutUs.jpeg';

import './Styles/Animation.css';

function AboutHero(props) {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="AboutHero">
      <div className='AboutHero-main'>
        <h1 className='AboutHero-main-header watch2'>Global advisory company that <span>drives results</span> through financial excellence</h1>
        <h3 className='AboutHero-main-header-sub watch2'>
          We offer end-to-end <span>financial advisory and accounting services</span> to companies of all sizes to maximize their operational efficiency.
        </h3>
      </div>
      <div className='chart-svg watch2'>
        <img src={aboutPic} alt="how we do" />
      </div>
    </div>
  );
}

export default AboutHero;
