import './Styles/LogoReveal3.css';
import logo from "./Resources/logo.png"

import {useNavigate } from "react-router-dom";

function LogoReveal3() {

  const navigate=useNavigate();
  return (
    <div className="LogoReveal3"  onClick={()=>{navigate("/"); window.location.reload(false);}}>
      <img className='logo' id='gif' src={logo} alt="logo" />
    </div>
  );
}

export default LogoReveal3;
