import './Styles/HowWeDoScreening.css';
import './Styles/Animation.css';
import people10 from './Resources/10people.svg';
import people7 from './Resources/7people.svg';
import people5 from './Resources/5people.svg';
import people3 from './Resources/3people.svg';
import people1 from './Resources/person.svg';
import arrow from './Resources/arrow-down.svg';
import { useEffect } from 'react';

function HowWeDoScreening() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="HowWeDoScreening">
      <h1 className='HowWeDoScreening-header watch'>Screening</h1>
      
      <div className='HowWeDoScreening-main'>
        <div className='HowWeDoScreening-main-card'>
          <div className='HowWeDoScreening-main-card-number'>01</div>
          <div className='HowWeDoScreening-main-card-data'>
            <div className='HowWeDoScreening-main-card-data-title watch'>Screening Test</div>
            <div className='HowWeDoScreening-main-card-data-desc watch'><br/>This step evaluates the fundamental strength of language, communication, and personality.<br/><br/> The initial phase of the assessment procedure involves evaluating the candidate's language proficiency and communication skills. Additionally, we examine personality characteristics, seeking individuals who display enthusiasm and complete dedication to their tasks.</div>
            {/* <div className='HowWeDoScreening-main-card-data-desc'>This step involves core services based technical skill tests. It also assesses problem-solving ability through various assessments. Only candidates with subject matter knowledge and exceptional results in this phase are selected.</div> */}
          </div>
          <div className='HowWeDoScreening-main-card-numbers'>
            <img  src={people10} alt="10 people" className='HowWeDoScreening-main-card-numbers-img watch'/>
            <div className='HowWeDoScreening-main-card-numbers-data watch'>7 of 10</div>
            <img src={arrow} alt="arrow" className='HowWeDoScreening-main-card-numbers-arrow watch'/>
          </div>
        </div>
        <div className='HowWeDoScreening-main-card'>
          <div className='HowWeDoScreening-main-card-number'>02</div>
          <div className='HowWeDoScreening-main-card-data'>
            <div className='HowWeDoScreening-main-card-data-title watch'>Technical Skill Review</div>
            <div className='HowWeDoScreening-main-card-data-desc watch'><br/>This step involves core services based technical skill tests. It also assesses problem-solving ability through various assessments. Only candidates with subject matter knowledge and exceptional results in this phase are selected.<br/><br/>In this stage, the focus is on evaluating technical expertise in core services along with problem-solving capabilities through a range of evaluations. Candidates demonstrating proficiency in the subject matter and outstanding performance in this part are chosen.</div>
          </div>
          <div className='HowWeDoScreening-main-card-numbers'>
            <img src={people7} alt="7 people" className='HowWeDoScreening-main-card-numbers-img watch'/>
            <div className='HowWeDoScreening-main-card-numbers-data watch'>5 of 10</div>
            <img src={arrow} alt="arrow" className='HowWeDoScreening-main-card-numbers-arrow watch'/>
          </div>
        </div>
        <div className='HowWeDoScreening-main-card'>
          <div className='HowWeDoScreening-main-card-number'>03</div>
          <div className='HowWeDoScreening-main-card-data'>
            <div className='HowWeDoScreening-main-card-data-title watch'>Meet the partner</div>
            <div className='HowWeDoScreening-main-card-data-desc watch'><br/>This phase involves an interview with a partner who is expert in their functional domain. A typical candidate has high hopes of interacting with subject experts. Evaluating their presence of mind, reasoning ability, and dedication is tested here.</div>
          </div>
          <div className='HowWeDoScreening-main-card-numbers'>
            <img src={people5} alt="5 people" className='HowWeDoScreening-main-card-numbers-img watch'/>
            <div className='HowWeDoScreening-main-card-numbers-data watch'>3 of 10</div>
            <img src={arrow} alt="arrow" className='HowWeDoScreening-main-card-numbers-arrow watch'/>
          </div>
        </div>
        <div className='HowWeDoScreening-main-card'>
          <div className='HowWeDoScreening-main-card-number'>04</div>
          <div className='HowWeDoScreening-main-card-data'>
            <div className='HowWeDoScreening-main-card-data-title watch'>Test Projects</div>
            <div className='HowWeDoScreening-main-card-data-desc watch'><br/>Candidates are assigned real-world scenarios and projects to demonstrate their competence and application of skills. Choice of personal v/s corporate, client v/s time, delivering best v/s delivery in time are evaluated here.</div>
          </div>
          <div className='HowWeDoScreening-main-card-numbers'>
            <img src={people3} alt="3 people" className='HowWeDoScreening-main-card-numbers-img watch'/>
            <div className='HowWeDoScreening-main-card-numbers-data watch'>2 of 10</div>
            <img src={arrow} alt="arrow" className='HowWeDoScreening-main-card-numbers-arrow watch'/>
          </div>
        </div>
        <div className='HowWeDoScreening-main-card'>
          <div className='HowWeDoScreening-main-card-number'>05</div>
          <div className='HowWeDoScreening-main-card-data'>
            <div className='HowWeDoScreening-main-card-data-title watch'>Final Call</div>
            <div className='HowWeDoScreening-main-card-data-desc watch'><br/>Candidates are put in scenarios to contribute to add value to our company. We thrive to excel in our field which needs constantly embracing change. Our focus is on the best talent for clients.</div>
          </div>
          <div className='HowWeDoScreening-main-card-numbers'>
            <img src={people1} alt="1 person" className='HowWeDoScreening-main-card-numbers-img watch'/>
            <div className='HowWeDoScreening-main-card-numbers-data watch'>1 selected</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowWeDoScreening;
