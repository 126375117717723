import './Styles/ServiceServices.css';

import './Styles/Animation.css';
import accountingSVG from "./Resources/accounting.svg";
import audit from "./Resources/audit.svg";
import payroll from "./Resources/payroll.svg";
import staffing from "./Resources/staffing.svg";
import tax from "./Resources/tax.svg";
import accounts from "./Resources/Accounts Management.svg";

import intuit from './Resources/intuit.png'
import xero from './Resources/xero.png'
import tally from './Resources/tally.png'
import wave from './Resources/wave.png'
import sage from './Resources/sage.svg'
import oracle from './Resources/oracle.png'
import caseware from './Resources/caseware.png'

import adp from './Resources/adp.png'
import bill from './Resources/bill.png'


import drake from './Resources/drake.png'
import turbotax from './Resources/IntuitTurboTax.png'
import proconnect from './Resources/intuitproconnect.png'
import thomson from './Resources/thomson.png'

import zoom from './Resources/zoom.png'
import teams from './Resources/teams.png'
// import accountingService from "./Resources/accounting-service.svg";
// import taxService from "./Resources/tax-service.svg";
// import bookkeepingService from "./Resources/Bookkeeping-service.svg";
// import auditService from "./Resources/Audit-service.svg";
// import remoteService from "./Resources/Remote-service.svg";
// import payrollService from "./Resources/Payroll-service.svg";

import { useEffect, useState } from 'react';


function ServiceServices() {


  const [serviceData,setServiceData]=useState(
    <>Get reliable professionals to help you with management accounts and take the right business decisions.
    </>
  )
  const [options,setOptions]=useState([
    {
    
    "Financial Statements Preparation":"Our team will meticulously manage, compile, and distribute both monthly and annual financial reports, encompassing data on earnings, expenses, and key business performance indicators. Additionally, we will provide a practical and comprehensive balance sheet.",
    "Cash Flow & Budgeting":"Cash flow management helps to understand the financial dynamics. And budget helps to limit spending. By crafting a consolidated cash flow management and budgeting approach, we will ensure your business has ample funds for seamless operation and enhanced growth.",
    "Sales Tax Preparation & Filing":"Filing sales tax returns requires on-time delivery and the highest level of accuracy. That’s why you need professionals like us who are adept at the preparation of sales tax returns and their timely filing. We have achieved this for many clients by automating our processes.",
    "Budgeted & Variance Analysis":"Every business prepares a budget. But most of them hardly ever check whether it exceeded or fell short. Here, financial advisors at CrossRight can help you build, manage, and maintain a budget and perform variance analysis to steer your business in the right direction.",
    "Key Performance Indicators":"KPIs help to assess whether the strategy is working or not, and hence, make necessary changes. Sometimes, businesses tend to get overwhelmed with which KPIs to focus on. We track, measure and report the most important metrics for your business to keep it on a solid growth trajectory."},
  
  ])
  const [optionsImg,setOptionsImg]=useState([intuit,tally,wave,sage,oracle,xero])
  const [headingElements,setHeadingElements]=useState(["Outsourced Management Accounting Services","Get reliable professionals to help you with management accounts and take the right business decisions.","Management Accounting equals informed decision"])
  // const [svgIcon,setSvgIcon]=useState(bookkeepingService);
  function changeActiveService(){
    let allServices=document.getElementsByClassName("ServiceServices-selector-select");
    for(let i=0;i<allServices.length;i++){
      if(allServices[i].classList.contains("ServiceServices-selector-select-active")){
        allServices[i].classList.remove("ServiceServices-selector-select-active")
      }
        
    }
  }

  function changeSlide(text,option,newImages,newHeading){
    document.getElementsByClassName("ServiceServices-main-desc-text")[0].classList.remove("show");
    document.getElementsByClassName("ServiceServices-main-desc-text")[0].classList.add("fadeOut");
    // document.getElementsByClassName("content")[0].classList.remove("show");
    // document.getElementsByClassName("content")[0].classList.add("fadeOut");
    // document.getElementsByClassName("content-svg")[0].classList.remove("showSvg");
    // document.getElementsByClassName("content-svg")[0].classList.add("hideSvg");
    setTimeout(()=>{
      setServiceData(option[0][Object.keys(option[0])[0]]);
      setOptions(option);
      setOptionsImg(newImages);
      setHeadingElements(newHeading);
      let allOptionsButton=document.getElementsByClassName("content-options-button");
      for(let i=0;i<allOptionsButton.length;i++){
        if(allOptionsButton[i].classList.contains("content-options-button-active")){
          allOptionsButton[i].classList.remove("content-options-button-active")
        }
          
      }
      allOptionsButton[0].classList.add("content-options-button-active");
      // setSvgIcon(svgIconTemp)
      document.getElementsByClassName("ServiceServices-main-desc-text")[0].classList.remove("fadeOut");
      document.getElementsByClassName("ServiceServices-main-desc-text")[0].classList.add("show");
      // document.getElementsByClassName("content")[0].classList.remove("fadeOut");
      // document.getElementsByClassName("content")[0].classList.add("show");
      // document.getElementsByClassName("content-svg")[0].classList.remove("hideSvg");
      // document.getElementsByClassName("content-svg")[0].classList.add("showSvg");
    },500)
    
  }

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{
    Animate();
    document.getElementsByClassName("content-options-button")[0].classList.add("content-options-button-active");
    if(localStorage.getItem("service")!==null){
      // console.log(localStorage.getItem("service"));
      let elems=document.getElementsByClassName("ServiceServices-selector-select");
      elems[parseInt(localStorage.getItem("service"))-1].click();
    }
  },[])

  return (
    <div className="ServiceServices">
      <h1 className='watch'>Services</h1>
        <div className='ServiceServices-main'>
          <div className='ServiceServices-selector'>
         
            <div className='ServiceServices-selector-select blue  ServiceServices-selector-select-active' onClick={(e)=>{
              changeSlide(<>Management Accounting provides crucial financial insights that enable informed, data-based decision-making for a business. And that’s why you need experts who furnish numbers with pinpoint accuracy.<br/><br/>
              We provide tailored solutions for management accounting to CPA and Accounting Firms across the United States and other countries.</>,[
                {
                "Financial Statements Preparation":"Our team will meticulously manage, compile, and distribute both monthly and annual financial reports, encompassing data on earnings, expenses, and key business performance indicators. Additionally, we will provide a practical and comprehensive balance sheet.",
                "Cash Flow & Budgeting":"Cash flow management helps to understand the financial dynamics. And budget helps to limit spending. By crafting a consolidated cash flow management and budgeting approach, we will ensure your business has ample funds for seamless operation and enhanced growth.",
                "Sales Tax Preparation & Filing":"Filing sales tax returns requires on-time delivery and the highest level of accuracy. That’s why you need professionals like us who are adept at the preparation of sales tax returns and their timely filing. We have achieved this for many clients by automating our processes.",
                "Budgeted & Variance Analysis":"Every business prepares a budget. But most of them hardly ever check whether it exceeded or fell short. Here, financial advisors at CrossRight can help you build, manage, and maintain a budget and perform variance analysis to steer your business in the right direction.",
                "Key Performance Indicators":"KPIs help to assess whether the strategy is working or not, and hence, make necessary changes. Sometimes, businesses tend to get overwhelmed with which KPIs to focus on. We track, measure and report the most important metrics for your business to keep it on a solid growth trajectory."},
              
              ],[intuit,tally,wave,sage,oracle,xero],["Outsourced Management Accounting Services","Get reliable professionals to help you with management accounts and take the right business decisions.","Management Accounting equals informed decision"])
              changeActiveService();
              e.target.classList.add("ServiceServices-selector-select-active")
            }}>
              <img src={accounts} alt="accounts"/>
                Management Accounts
            </div>
            <div className='ServiceServices-selector-select maati' onClick={(e)=>{
              changeSlide(<>Taxes are complicated and tax professionals must know the details, the latest updates, and all deductions available for tax planning. And, we combine all of that with technical software skills to make the most of it.<br/><br/>
              Whether it be US income tax returns, partnership tax returns, corporate tax returns, payroll tax returns, or any diverse tax locales, we have got your back with taxation experts on our side.</>,[
                {
                "Individual Tax Return":"We assist you in preparing individual tax returns by complying with all the relevant norms and ensuring maximum tax savings. We specialize in preparing and filing individual tax returns for the following countries:USA: Form 1040, 1040A, 1040EZ, 1040NR; Canada: Form T1",
                "Business Tax Return":"We offer a full range of tax preparation and filing solutions for corporate and partnership establishments. We cater to all small, medium, and large enterprises that have the need to manage their taxation in the most efficient manner. Countries we serve:USA: Form 1120 (for corporates) and 1065 (for partnerships);Canada: Form T2",
                "Estate & Trust Tax Return":"Tax laws and rules governing the administration of estates, particularly with respect to taxes, are complex. Our estate specialists assist you in estate tax planning to minimize taxes and ensure the proper distribution of assets across heirs. We provide tax return services for the following countries:USA: Form 1041;Canada: Form T3",
                "Non-profit Tax Return":"Non-profit tax specialists at CrossRight can handle all aspects of nonprofit tax planning, tax-related regulatory compliance, tax analysis, and more. We work closely with you to present an accurate and compelling picture of your nonprofit’s financial health. The scope of our services span multiple countries:USA: Form 990;Canada: T1044"},
                
              
              ],[drake,turbotax,proconnect,thomson],["Outsourced Tax Return Preparation Services","Hire taxation professionals to simplify tax calculation, preparation, and on-time filing of your tax returns.","Taxation is Complex, We Make it Simple for You"])
              changeActiveService();
              e.target.classList.add("ServiceServices-selector-select-active")
            }}>
            <img src={tax} alt="tax"/>
                Tax Return Preparation
            </div>
            <div className='ServiceServices-selector-select blue' onClick={(e)=>{
              changeSlide(<>Bookkeeping and accounting are foundational aspects of any business, but at the same time consume a considerable bandwidth. We can lend you a helping hand here.<br/><br/>
              We manage and effectively handle multiple accounting and banking from diverse industries systematically and productively.</>,[
                {
                "Amortization & Depreciation":"We offer accurate recording of amortization and depreciation as per the norms laid out by the authorities. This helps to reduce the carrying value of assets and recognize expenses as assets are used over time. It also helps in estimating the useful life and its periodic replacement.",
                "Journal Entry Posting":"Obtain professional manual journal entry assistance to ensure each entry in the journals is verified with adequate explanations and detailed descriptions and reasons for the transactions as well as accurately matching the debit and credit totals in the balance sheet and more.",
                "Bank & Credit Card Reconciliation":"Our team offers specialized services in bank and credit card reconciliations, encompassing partial and complete reconciliations, aligning your internal financial records with bank statements, matching vendor invoices to your primary buyer ledger, and beyond.",
                "Annual Payroll & Sales Tax Reconciliation":"At the end of the year, there are certain adjustments that need to be made, with respect to payroll compliance and sales tax. Here, CrossRight can help you out with annual payroll and sales tax reconciliation."},
              
              ],[intuit,tally,wave,sage,oracle,xero],["Outsourced Accounting and Bookkeeping Services","Hire proficient experts to outsource your day-to-day bookkeeping and accounting and improve your operational efficiency.","Accurate Accounting & Bookkeeping is the Foundation"])
              
              changeActiveService();
              e.target.classList.add("ServiceServices-selector-select-active")
            }}>
            <img src={accountingSVG} alt="accounting"/>
                Accounts & Bookkeeping
            </div>
            <div className='ServiceServices-selector-select green' onClick={(e)=>{
              changeSlide(<>An audit is a high-stakes, time-consuming and resource-intensive task. Only qualified and experienced professionals can vouch for the authenticity, relevance, and quality of a client’s financial statements. Hence, it needs to be undertaken with utmost diligence.<br/><br/>
              We provide a comprehensive assessment of financial records to gauge the fair play of a business and successfully avoid any fraud or discrepancies for better stakeholder relationships.</>,[
                {
                "SOC Type 1 & Type 2 Audit":"SOC 1 and 2 audits are required to implement internal controls and maintain a high degree of security. For that, CrossRight can execute SOC type 1 and 2 audits, and ensures your service organization's controls are effectively designed and operating.",
                "Review of Financial Statements":"A financial statement review is about performing analytical procedures, inquiries, and other procedures to obtain “limited assurance” on the financial statements. We as an independent advisory firm like CrossRight can carry out the review process as a part of the overall audit.",
                "Compilation of Financial Statements":"Before presenting the financial statements to stakeholders, CPAs need to make year-end adjustments, add footnotes or disclosures and overcome the limitations of accounting software. For that, CrossRight can lend you a helping hand to compile financial statements.",
                "Financial Statements Audit":"A timely financial statement audit assures stakeholders and bolsters your reputation. Here, choosing an experienced advisory firm like CrossRight can help gain trust among your stakeholders. We are well-equipped with technology & resources, and the bandwidth to manage your financial audits."},
              
              ],[caseware],["Outsourced Audit & Assurance Servicess","Maximize efficiency and accuracy in your audit and assurance procedures by partnering with experienced CPA and CA professionals.","Auditing is at the Pinnacle of Accounting"])
              
              changeActiveService();
              e.target.classList.add("ServiceServices-selector-select-active")
            }}>
            <img src={audit} alt="audit"/>
                Audit and Assurance
            </div>
            <div className='ServiceServices-selector-select maati' onClick={(e)=>{
              changeSlide(<>Remote staffing is now a preferred mode of hiring professionals as it reduces personnel and infrastructure costs. But to make it successful, you need to hire the right people for the job. Finding the right talent is difficult and that’s where we come in.<br/><br/>
              We can be your extended team to manage all things accounting and finance so that you can focus on scaling up your business. </>,[
                {
                "Bookkeeper":"Get a skilled bookkeeping professional to help you with daily accounting and bookkeeping requirements. Once you securely connect your financial accounts, we’ll introduce you to a dedicated bookkeeper.",
                "Accounting Manager":"Hire an accounting manager to take care of all your accounting needs. If you are a one-person company or a small firm and need assistance from an accounting professional, this is the right choice for you.",
                "Audit Associate":"Got tangled in a complicated audit process and running out of time? You can hire a qualified audit associate with years of experience to help you out. This audit associate can be a part of your internal team or act as an independent auditor for your company.",
                "Payroll Manager":"Payroll is complex, and if you are finding a hard time dealing with it, leave it up to us. We can fix you up with an experienced payroll manager familiar with taxation laws and compliance, as well as payroll software, and make your payroll a breeze.",
                "Tax Manager":"Get a full range of taxation services from tax preparation to tax review and compliance in a timely manner and at a fraction of the cost. By hiring a tax manager, you get all the benefits of having an in-house taxation professional but in a more affordable manner."},
              
              ],[zoom,teams],["Remote Staffing Solutions","Extend your team by hiring remote CPA, CA, accountants, payroll, and tax professionals for maximum business growth.","Best Outsourced Finance Professionals On Demand"])
            
              changeActiveService();
              e.target.classList.add("ServiceServices-selector-select-active")
            }}>
            <img src={staffing} alt="staffing"/>
                Remote Staffing
            </div>
            <div className='ServiceServices-selector-select red' onClick={(e)=>{
              changeSlide(<>Payroll though seems simple, is in fact complicated. Payroll administrators have to take care of compliance, taxation, accuracy, and confidentiality. Even a small mistake can prove extremely expensive.<br/> <br/>
              We are adept at payroll management, ensure full compliance with wages and labor act, and hence can serve your client’s needs with full transparency.</>,[
                {
                "Payroll Accounting":"          Our payroll accounting experts ensure the filing of federal, state, and local tax returns while processing payment for all payroll tax liabilities. We help you integrate payroll processing with accounting systems if needed to automate end-to-end business payroll management.",
                "Preparing Payroll Sheet & Calculation of Liability":"          We provide paycheck and payroll sheet creation on a weekly, bi-weekly, monthly, semi-monthly, or yearly payroll basis. We accurately factor in reimbursements, loan advances, leaves, and other dynamic HR inputs while managing varied salary structures.",
                "Payroll Tax Reporting (Form 941, W2, UI)":"          Our expert payroll team and taxation experts at CrossRight help you evaluate the tax liability of every employee to report income tax, social security tax, or Medicare tax withheld from employee's paychecks and pay the employer's portion of social security or Medicare tax. This helps to determine the exact tax liability and ensure timely filing of tax returns.",
                "Payroll Data Maintenance":"          Our payroll data maintenance services include additions, changes, or deletions in payroll-related data over time due to changes in laws, monthly accruals for statutory bonuses, and compensation to be paid to employees in case of resignation."},
              
              ],[adp,bill],["Outsourced Payroll Processing Services","Hire payroll management professionals to process and manage timely and accurate payroll processing.","Better Payroll Management For Highest Employee Morale"])
            
              changeActiveService();
              e.target.classList.add("ServiceServices-selector-select-active")
            }}>
            <img src={payroll} alt="payroll"/>
                Payroll Processing
            </div>
          </div>
          <div className='ServiceServices-main-desc'>
            <div className='ServiceServices-main-desc-text'>
              <div className='content-data-header'>
                
                <div className='content-data-header-heading'>
                {headingElements[0]}
                </div>
                <div className='content-data-header-sub'>
                "{headingElements[2]}"
                </div>
                <div className='content-data-header-sub'>
                {headingElements[1]}
                </div>
                
              </div>
              <div className='content'>
                <div className='content-data'>
                  
                  <div className='content-data-text'>
                   {serviceData}
                  </div>
                  <div className='content-data-tools'>
                    {optionsImg.map((optionImg=><img src={optionImg} alt="Options Img"/>))}
                  </div>
                  
                </div>
                
                <div className='content-options'>
                  {
                  Object.keys(options[0]).map((option,key)=><button className='content-options-button' onClick={(e)=>{
                    let allOptionsButton=document.getElementsByClassName("content-options-button");
                    for(let i=0;i<allOptionsButton.length;i++){
                      if(allOptionsButton[i].classList.contains("content-options-button-active")){
                        allOptionsButton[i].classList.remove("content-options-button-active")
                      }
                        
                    }

                    document.getElementsByClassName("content-data-text")[0].classList.remove("show");
                    document.getElementsByClassName("content-data-text")[0].classList.add("fadeOut");
                    // document.getElementsByClassName("content-svg")[0].classList.remove("showSvg");
                    // document.getElementsByClassName("content-svg")[0].classList.add("hideSvg");
                    setTimeout(()=>{
                      setServiceData(options[0][option])
                      // setSvgIcon(svgIconTemp)
                      document.getElementsByClassName("content-data-text")[0].classList.remove("fadeOut");
                      document.getElementsByClassName("content-data-text")[0].classList.add("show");
                      // document.getElementsByClassName("content-svg")[0].classList.remove("hideSvg");
                      // document.getElementsByClassName("content-svg")[0].classList.add("showSvg");
                    },500)



                    e.target.classList.add("content-options-button-active");
                    
                  }
                  } key={key}>{option}</button>)
                  }
                </div>
              </div>
              
            </div>
          
          </div>
        </div>
        
    </div>
  );
}

export default ServiceServices;
