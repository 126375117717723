import './Styles/AboutIndustries.css';
import cpa from './Resources/cpa.svg'
import it from './Resources/it.svg'
import property from './Resources/property.svg'
import ecommerce from './Resources/ecommerce.svg'
import beverage from './Resources/beverage.svg'
import hospitality from './Resources/hospitality 2.svg'
import pharma from './Resources/pharma.svg'
import retail from './Resources/retail.svg'

import './Styles/Animation.css';
import { useEffect } from 'react';

function AboutIndustries(props) {

  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="AboutIndustries">
      <h1 className='watch'>Industries We Serve</h1>
      <div className='AboutIndustries-main'>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={cpa} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          CPA Firms
          </div>
        </div>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={hospitality} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          Hospitality
          </div>
        </div>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={pharma} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          Pharma
          </div>
        </div>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={retail} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          Retail & Wholesale
          </div>
        </div>
        
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={beverage} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          Food & Beverage
          </div>
        </div>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={it} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          IT Services
          </div>
        </div>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={property} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          Property Management
          </div>
        </div>
        <div className='AboutIndustries-main-card'>
          <div className='AboutIndustries-main-card-img watch'>
            <img src={ecommerce} alt="Service Icon"/>
          </div>
          <div className='AboutIndustries-main-card-title watch'>
          E-commerce
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutIndustries;
