import './Styles/ServiceHero.css';
import './Styles/Animation.css';
import chart from './Resources/serviceshero.svg';
import { useEffect } from 'react';



function ServiceHero(props) {

  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="ServiceHero">
      <div className='ServiceHero-main'>
        <h1 className='ServiceHero-main-header watch2'>Financial advisory services that go <span>beyond the numbers</span></h1>
        <h3 className='ServiceHero-main-header-sub watch2'>
        Our experts possess the top-notch financial experience to dive deep beyond the surface and analyze every financial aspect with utmost detail for the best outcomes.
        </h3>
      </div>
      <div className='chart-svg watch2'>
        <img src={chart} alt="chart" />
      </div>
    </div>
  );
}

export default ServiceHero;
