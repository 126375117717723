import { useEffect } from 'react';
import './Styles/AboutMetrics.css';

import './Styles/Animation.css';

function AboutMetrics() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  

  

  return (
    <div className="AboutMetrics">
     
      <div className='AboutMetrics-main'>
      <div className='AboutMetrics-main-card'>
        <div className='AboutMetrics-main-card-number watch'>5000<span>+</span></div>
        <div className='AboutMetrics-main-card-desc watch'>Hours Served</div>
      </div>
      <div className='AboutMetrics-main-card'>
        <div className='AboutMetrics-main-card-number watch'>60<span>+</span></div>
        <div className='AboutMetrics-main-card-desc watch'>Projects</div>
      </div>
      <div className='AboutMetrics-main-card'>
        <div className='AboutMetrics-main-card-number watch'>40<span>+</span></div>
        <div className='AboutMetrics-main-card-desc watch'>Clients served</div>
      </div>
      <div className='AboutMetrics-main-card'>
        <div className='AboutMetrics-main-card-number watch'>15<span>+</span></div>
        <div className='AboutMetrics-main-card-desc watch'>Team Size</div>
      </div>
      
      </div>
    </div>
  );
}

export default AboutMetrics;
