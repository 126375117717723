import './Styles/Pricing.css';
import './Styles/Animation.css';
// import LogoReveal from './LogoReveal';
import Navbar2 from './Navbar2';
import { useEffect, useState } from 'react';
import ReadyToChoose from './ReadyToChoose';
import Footer from './Footer';
import PricingHero from './PricingHero';
import PricingBenefits from './PricingBenefits';
import PricingDialog from './PricingDialog';
import HoveringBar from './HoveringBar';

function Pricing() {
  const [loaded,setLoaded]=useState(false);
  useEffect(()=>{
    setTimeout(()=>{
      setLoaded(true);
    },2000)
  })
  return (
    <div className="Pricing">
      <Navbar2 noAnimation={true}/>
      {
        loaded &&
        <>
          <PricingHero />
          <PricingBenefits />
          <PricingDialog />
          <ReadyToChoose />
          <Footer />
          <HoveringBar />
        </>
        
      }
      
    </div>
  );
}

export default Pricing;
