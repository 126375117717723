import './Styles/Advantages.css';
import './Styles/Animation.css';
import arrowRight from "./Resources/chevron-right.svg"
import { useEffect } from 'react';
import './Styles/Animation.css';
// import ill from "./Resources/ill1.png"
// import ill from "./Resources/pointingMan.png"

// import { useEffect } from 'react';


function Animate(){
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // It's visible. Add the animation class here!
        if(entry.target.classList.contains("watch2")){
          entry.target.classList.add("inView2");
        }
        else if(entry.target.classList.contains("watch")){
          entry.target.classList.add("inView");
        }
        
      }
      else{
        entry.target.classList.remove("inView");
        entry.target.classList.remove("inView2");
      }
    });
  });
  
  // Tell the observer which elements to track
  let elementsToWatch=document.querySelectorAll('.watch');
  elementsToWatch.forEach(element=>{
    observer.observe(element);
  })
  let elementsToWatch2=document.querySelectorAll('.watch2');
  elementsToWatch2.forEach(element=>{
    observer.observe(element);
  })

}


function Advantages(props) {
  const {
    staticText,
    dynamicText,
  }=props
  // useEffect(() => {

  //   let dynamicUL=document.getElementsByClassName("dynamic-animation");
  //   let timeoutID,timeoutID2,timeoutID3;
  //   var loop = () => {
  //     // timeoutID = setTimeout(() => {
  //       dynamicUL[0].classList.add("paused");      
  //     // },0);
  //     timeoutID2 = setTimeout(()=>{
  //       dynamicUL[0].classList.remove("paused");
  //       timeoutID3 = setTimeout(()=>{
  //         loop();
  //       },2000);
  //     },100);
      
  //       // loop()
  //     // }, 2300)
  //   }
  //   loop() 


  //   // let timeoutID2;
  //   // const timeoutID = setInterval(()=>{
  //   //   let dynamicUL=document.getElementsByClassName("dynamic-animation");
  //   //   if(dynamicUL.length>0){
  //   //     dynamicUL[0].classList.add("paused");
  //   //     timeoutID2 = setTimeout(()=>{
  //   //       dynamicUL[0].classList.remove("paused");
  //   //     },500)
  //   //   }
  //   // },2000)

  //   return () => {
  //     // 👇️ clear timeout when the component unmounts
  //     clearInterval(timeoutID);
  //     clearTimeout(timeoutID2);
  //     clearTimeout(timeoutID3);
  //   };
  // });
  useEffect(()=>{
    Animate();
  },[])
  return (
    <div className="Advantages watch2">
      <div className='Advantages-static'>{staticText}</div>
      <div className='Advantages-dynamic'>
        <ul className='dynamic-animation'>
        {dynamicText.map((text,key)=><li key={key}>{text}</li>)}
        </ul>
      </div>
      <div className='Advantages-button'> 
      <a href='https://calendly.com/crossright/' target="_blank">
      <button>
          <div className='arrow-parent'>
            <img src={arrowRight} alt="arrow"/>
          </div>
          
          Let's work together
        </button>
      </a>
        
        
      </div>
      {/* <img className='ill' src={ill} alt="Work" /> */}
    </div>
  );
}

export default Advantages;
