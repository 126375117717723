import './Styles/ReadyToChoose.css';
import './Styles/Animation.css';
import mail from './Resources/mail2.svg';
import call from './Resources/call2.svg';
import explore from './Resources/explore2.svg';

import {useNavigate } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore,collection, addDoc  } from "firebase/firestore";
import { useEffect } from 'react';




function ReadyToChoose() {


  const navigate=useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyDIO56H7sMsth2kvTPB5-a79M7o8IockkE",
    authDomain: "crossright-50fa6.firebaseapp.com",
    projectId: "crossright-50fa6",
    storageBucket: "crossright-50fa6.appspot.com",
    messagingSenderId: "986658181564",
    appId: "1:986658181564:web:7ae35c1c477168cdd9dbb9",
    measurementId: "G-Q797J9D3QG"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  function toggleModal(){
    if(document.getElementsByClassName("ReadyToChoose-Modal")[0].classList.contains("hideModal")){
      document.getElementsByClassName("ReadyToChoose-Modal")[0].classList.remove("hideModal")
    }
    else{
      document.getElementsByClassName("ReadyToChoose-Modal")[0].classList.add("hideModal")
    }
  }
  function displayAlertMessage(message){
    document.getElementById("AlertMessage").innerHTML=message
    document.getElementById("AlertMessage").classList.add("AlertMessage-show");
    setTimeout(()=>{
      document.getElementById("AlertMessage").classList.remove("AlertMessage-show");
    },5000)
  }

  async function submitData(){

    try {
      const docRef = await addDoc(collection(db, "usersFormData"), {
        fullName: document.getElementById("fullName").value,
        companyName: document.getElementById("companyName").value,
        typeOfService: document.getElementById("typeOfService").value,
        email: document.getElementById("email").value,
        thoughts: document.getElementById("thoughts").value,
      });
      toggleModal();
      displayAlertMessage("We have received your data, we shall contact you in 24 hours.")
    } catch (e) {
      console.error("Error adding document: ", e);
      displayAlertMessage("We are facing issues at our end, please try after sometime.")
    }
  }
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{
    Animate();
  },[])
  return (
    <>

      <div className="ReadyToChoose">
        <h1 className=''>
        Ready To Lift-off?
        </h1>
        <div className="ReadyToChoose-main">
          <div className='ReadyToChoose-main-content'>
            <div className='ReadyToChoose-main-content-icon ' >
              <img  src={call} alt="call" />
            </div>
            <div className='ReadyToChoose-main-content-head '>
              Schedule a call
            </div>
            <div className='ReadyToChoose-main-content-sub '>
            Hey! Need help with a project? We’re just a call away.
            </div>
            <div className='ReadyToChoose-main-content-cta '>
            <a href='https://calendly.com/crossright/' target="_blank"><button>Book a Call</button></a>
            </div>
          </div>
          <div className='ReadyToChoose-main-content'>
          <div className='ReadyToChoose-main-content-icon '>
              <img src={mail} alt="mail" />
            </div>
              <div className='ReadyToChoose-main-content-head '>
                Prompt response
              </div>
              <div className='ReadyToChoose-main-content-sub '>
                Share your requirements and we’ll get back within 24 hours.
              </div>
              <div className='ReadyToChoose-main-content-cta '>
                <button onClick={toggleModal}>Fill up the form</button>
              </div>
          </div>
          <div className='ReadyToChoose-main-content'>
          <div className='ReadyToChoose-main-content-icon '>
              <img src={explore} alt="explore" />
            </div>
              <div className='ReadyToChoose-main-content-head '>
                Explore other services
              </div>
              <div className='ReadyToChoose-main-content-sub '>
                Didn’t find the thing you are looking for? Have a look at what we have to offer.
              </div>
              <div className='ReadyToChoose-main-content-cta '>
                <button onClick={()=>{
                  navigate("/Services")
                }}>Services</button>
              </div>
          </div>
        </div>

        
      </div>
      <div className='ReadyToChoose-Modal hideModal'>
        <button onClick={toggleModal} className='ReadyToChoose-Modal-Close'>X</button>
        <h2>Share your requirements below and we’ll get back within <span>24 hours</span>.</h2>
        <div className='ReadyToChoose-Modal-inputs'>
          <h3>My name is (full name)</h3>
          <input id='fullName' className='ReadyToChoose-Modal-inputs-input' type="text" />
        </div>
        <div className='ReadyToChoose-Modal-inputs'>
          <h3>From (Company name)</h3>
          <input id='companyName' className='ReadyToChoose-Modal-inputs-input' type="text" />
        </div>
        <div className='ReadyToChoose-Modal-inputs'>
          <h3>If you can help me with (type of service)</h3>
          <input id='typeOfService' className='ReadyToChoose-Modal-inputs-input' type="text" />
        </div>
        <div className='ReadyToChoose-Modal-inputs'>
          <h3>You can reach me at (email) </h3>
          <input id='email' className='ReadyToChoose-Modal-inputs-input' type="email" />
        </div>
        <div className='ReadyToChoose-Modal-inputs'>
          <h3>I want to add further details (Share your thoughts)</h3>
          <input id='thoughts' className='ReadyToChoose-Modal-inputs-input' type="text" />
        </div>
        <div className='ReadyToChoose-Modal-inputs'>
          <button onClick={submitData}>Submit</button>
        </div>
      </div>
      <div id='AlertMessage' className='AlertMessage'>
          
      </div>
    </>
    
  );
}

export default ReadyToChoose;
