import './Styles/PricingBenefits.css';
import './Styles/Animation.css';
import { useEffect } from 'react';

function PricingBenefits() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="PricingBenefits">
      <h1 className='PricingBenefits-header watch'>We got solutions for all your needs</h1>
      <h2 className='PricingBenefits-sub watch'>Our solutions extend beyond traditional accounting and bookkeeping. Our financial experts are adept at solving problems and boost your productivity.</h2>
      <div className='PricingBenefits-main'>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>High overhead cost?</div>
          <div className='PricingBenefits-main-card-desc'>
           We can help you cut your salary cost by 60% by offering you our hourly and fixed monthly model, based on your needs. Only pay for what you need.

          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>Financial professionals charging too high?</div>
          <div className='PricingBenefits-main-card-desc'>
          You don’t need to have deep pockets to hire experienced and talented financial professionals. We have focused and solution-oriented professionals to get the job done at a fraction of the cost.

          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>Delayed reports & no financial insights?</div>
          <div className='PricingBenefits-main-card-desc'>
          We are committed to submit timely reports with in-depth financial analysis for better decision making.
          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>Limited software proficiency?</div>
          <div className='PricingBenefits-main-card-desc'>
          Our experts are proficient in almost all industry-standard software for accounting, taxation, payroll, and audit. 

          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>Inadequate internal control?</div>
          <div className='PricingBenefits-main-card-desc'>
          By streamlining the workflow through automated processes, we minimize the need to close control. We comply with industry policies and procedures, establish clear lines of communication and monitor performance regularly.
          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>Don’t know whom to co-ordinate?</div>
          <div className='PricingBenefits-main-card-desc'>
          We appoint a single point of contact, a senior manager or a team leader who shall co-ordinate with you on project updates and delivery.

          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>No set processes?</div>
          <div className='PricingBenefits-main-card-desc'>
          We have a set system of managing multiple work flows through appropriate task allocation, project tracking and KPI reporting.
          </div>
      </div>
      <div className='PricingBenefits-main-card watch'>
          <div className='PricingBenefits-main-card-title'>No access to qualified staff and frequent employee turnover?</div>
          <div className='PricingBenefits-main-card-desc'>
          Whether you opt for a dedicated resource or team, our internal structure calls for teamwork. That means every work is thoroughly reviewed for maximum accuracy.
          </div>
      </div>
        
      </div>
    </div>
  );
}

export default PricingBenefits;
