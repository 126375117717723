import './Styles/CareerWhat.css';
import './Styles/Animation.css';
import privacy from './Resources/privacy.svg'
import datasecurity from './Resources/dataSecurity.svg'
import access from './Resources/access.svg'
import { useEffect } from 'react';

function CareerWhat() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="CareerWhat">
      <h1 className='CareerWhat-header watch'>What makes CrossRight a great place to work?</h1>
      <div className="CareerWhat-main">
        
        <div className="CareerWhat-main-right">
        <div className="CareerWhat-main-right-card">
          <div className="CareerWhat-main-right-card-number">1</div>
          <div className="CareerWhat-main-right-card-text watch">Constructive feedback</div>
        </div>
        <div className="CareerWhat-main-right-card">
          <div className="CareerWhat-main-right-card-number">2</div>
          <div className="CareerWhat-main-right-card-text watch">No micromanagement</div>
        </div>
        <div className="CareerWhat-main-right-card">
          <div className="CareerWhat-main-right-card-number">3</div>
          <div className="CareerWhat-main-right-card-text watch">Learning & development</div>
        </div>
        <div className="CareerWhat-main-right-card">
          <div className="CareerWhat-main-right-card-number">4</div>
          <div className="CareerWhat-main-right-card-text watch">Global projects</div>
        </div>
        <div className="CareerWhat-main-right-card">
          <div className="CareerWhat-main-right-card-number">5</div>
          <div className="CareerWhat-main-right-card-text watch">Inclusive & open culture</div>
        </div> 
        
        </div>
      </div>
    </div>
  );
}

export default CareerWhat;
