import { useEffect } from 'react';
import './Styles/Testimonial.css';
import './Styles/Animation.css';

function Testimonial(props) {

  const {
    content,
    personName,
    designation,
    dark,
    companyName
  }=props;

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{
    Animate()
  },[])

  return (
    <>

      <div className={dark?"Testimonial dark":"Testimonial"}>
        <div className='Testimonial-content watch'>
          {content}
        </div>
        <div className='Testimonial-by'>
          <div className='Testimonial-by-person watch'>
            <div className='Testimonial-by-person-details'>
              <div className='Testimonial-by-person-details-name'>
                {personName}
              </div>
              {/* <div className='Testimonial-by-person-details-designation'>
                {designation}
              </div> */}
            </div>
          </div>
          <div className='Testimonial-by-company watch'>
            <div className='Testimonial-by-company-image'>
              <b>{companyName}</b>
            </div>
          </div>
        </div>
      </div>
    
    </>
    
  );
}

export default Testimonial;
