import './Styles/CareerHero.css';
import './Styles/Animation.css';
import joinourteam from './Resources/joinourteam.svg';
import { useEffect } from 'react';


function CareerHero() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <div className="CareerHero">
      <div className='CareerHero-main'>
        
        <h1 className='CareerHero-main-header watch2'>We’re always on the lookout for <span>passionate people.</span></h1>
        <h3 className='CareerHero-main-header-sub watch2'>
        Do you intend to make your mark in the finance industry? Then this is the right place for you.
        </h3>
        <button onClick={()=>{
          document.getElementsByClassName('CareerJobs')[0].scrollIntoView();
        }} className='CareerHero-main-action watch2'>View Jobs</button>
      </div>
      <div className='chart-svg watch2'>
        <img src={joinourteam} alt="how we do" />
      </div>
    </div>
  );
}

export default CareerHero;
