import './Styles/About.css';

import './Styles/Animation.css';
// import LogoReveal from './LogoReveal';
import Navbar2 from './Navbar2';
import { useEffect, useState } from 'react';
import AboutHero from './AboutHero';
import AboutWho from './AboutWho';
import AboutStory from './AboutStory';
import AboutPhotos from './AboutPhotos';
import AboutValues from './AboutValues';
import AboutMetrics from './AboutMetrics';
import ReadyToChoose from './ReadyToChoose';
import Footer from './Footer';
import AboutIndustries from './AboutIndustries';
import HoveringBar from './HoveringBar';

function About() {
  const [loaded,setLoaded]=useState(false);
  useEffect(()=>{
    setTimeout(()=>{
      setLoaded(true);
    },2000)
  })
  return (
    <div className="About">
      <Navbar2 noAnimation={true}/>
      {
        loaded &&
        <>
          <AboutHero />
          <AboutWho />
          <AboutStory />
          <AboutPhotos />
          <AboutValues />
          <AboutIndustries />
          <AboutMetrics />
          <ReadyToChoose />
          <Footer />
          <HoveringBar />
        </>
        
      }
      
    </div>
  );
}

export default About;
