import './Styles/PrivacyDetails.css';
import './Styles/Animation.css';
import { useEffect } from 'react';

function PrivacyDetails() {

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])
  return (
    <>
    <div className='PrivacyDetails'>
      <h1 className='PrivacyDetails-head watch'>Privacy Policy</h1>
      <div className='PrivacyDetails-update watch'>Last Updated: 10-06-2023</div>
      <div className='PrivacyDetails-content watch'>
        Welcome to www.crossright.in (the “Site”). This website’s privacy policy (“Policy”) describes how CrossRight and its subsidiaries (“We”, “we”, or “CrossRight”) may use and disclose information that we may collect about you through the site.
      </div>
      <div className='PrivacyDetails-heading watch'>
        Collection and Use of Personal Information
      </div>
      <div className='PrivacyDetails-content watch'>
      When you use the site or register with it, we may collect certain “Information about you as a visitor to the site,” which identifies you as an individual or relates as an identifiable individual. The categories of Personal Information we may collect will include your name and contact information (email, address and phone number), your search criteria and other preferences, or any login ID or email address and password created by you.
      </div>
      <div className='PrivacyDetails-content watch'>
      We use this information to register you with the Site, to provide you with information regarding or related to remote staffing and career-related information; and to otherwise provide you with requested information or services. We may from time to time use this Information to send you automated email messages or marketing materials, e books, newsletters, etc. regarding our services. We may also use this Information for our business purposes, such as data analysis, audits, and improving our services.
      </div>
      <div className='PrivacyDetails-heading watch'>
        Disclosures of Personal Information
      </div>
      <div className='PrivacyDetails-content watch'>
      CrossRight may disclose Personal Information to third parties in the following circumstances:
      </div>
      <div className='PrivacyDetails-content watch'>
      To our third-party service providers who perform business functions and services on our behalf; To comply with applicable laws, the service of legal process, or if we reasonably believe that such action is necessary to (a) comply with the law requiring such disclosure; (b) protect the rights or property of CrossRight or its affiliated companies; (c) prevent a crime or protect national security, or (d) protect the personal safety of the users or the public. We also may disclose or transfer information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings).
      </div>
      <div className='PrivacyDetails-heading watch'>
      Collection and Use of Other Information
      </div>
      <div className='PrivacyDetails-content watch'>
      “Other Information” is any information that does not reveal your specific identity or does not directly relate to an identifiable individual. We may collect Other Information in a variety of ways, including:
      </div>
      <div className='PrivacyDetails-heading watch'>
      Through your browser or  device:
      </div>
      <div className='PrivacyDetails-content watch'>
      Certain information is collected by most browsers or automatically through your device, such as your Media Access Control (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and model, language and Internet browser type/version. We use this information to ensure that the Site functions properly.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Using cookies:
      </div>
      <div className='PrivacyDetails-content watch'>
      Cookies are pieces of information stored on the computer that you are using. Cookies allow us to collect information such as browser type, time spent on the Site, pages visited, registrations, submissions, demographics, information requests, language preferences, and other traffic data. We use the information for security purposes, to facilitate navigation, to display information more effectively, and to personalize your experience. We also gather statistical information about use of the Site to enhance its functionality, understand how it is used and assist us with resolving questions/issues about it. We may also use cookies and other technologies in online advertising campaigns to track responses to our ads.
      </div>
      <div className='PrivacyDetails-content watch'>
      If you do not want information collected through the use of cookies, you may disable them manually. Most browsers allow you to automatically decline cookies, or be given the choice of declining or accepting cookies from a particular site. If, however you do not accept cookies, you may experience some inconvenience in terms of user experience during use of the Site.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Using pixel tags and other similar technologies:
      </div>
      <div className='PrivacyDetails-content watch'>
      Pixel tags (also known as web beacons and clear GIFs) may be used to, among other things in order to track the actions of Site users and email recipients, market our services to you, measure the success of our marketing campaigns and compile statistics about Site usage and response rates.
      </div>
      <div className='PrivacyDetails-content watch'>
      Analytics. We may use third-party analytics providers such as Google Analytics on the Site. Google Analytics uses cookies and similar technologies to collect and analyze information about use of the Services and report on activities and trends. Google Analytics may also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices by visiting www.google.com/policies/privacy/partners/ You can also opt out by downloading the Google Analytics opt-out browser add-on available at https://tools.google.com/dlpage/gaoptout.
      </div>
      <div className='PrivacyDetails-heading watch'>
      IP Address:
      </div>
      <div className='PrivacyDetails-content watch'>
      Your IP Address is a number that is automatically assigned to your computer by your Internet Service Provider. An IP Address may be identified and logged automatically in our server log files whenever a user accesses the Site, along with the time of the visit and the pages visited during a session. We use IP Addresses for purposes such as calculating usage levels, diagnosing server problems, and administering the Site. We may also derive your approximate location from your IP Address. Please note that we may use and disclose Other Information for purpose as listed earlier.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Retargeting
      </div>
      <div className='PrivacyDetails-content watch'>
      We may use third-party advertising companies to serve ads regarding our services when you access and use other websites, based on information relating to your use of the Site. To do so, these companies may place or recognize a unique cookie on your browser (including thorough use of pixel tags). By utilizing the Site, you consent to the use of cookie or other tracking technologies to serve you retargeted advertising.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Third Party Services
      </div>
      <div className='PrivacyDetails-content watch'>
      This Privacy Policy does not address and we are not responsible for the privacy, information or other practices of any third parties, including any third party operating any site or service to which the Site links.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Security
      </div>
      <div className='PrivacyDetails-content watch'>
      We use reasonable organizational, technical and administrative measures designed to protect Personal Information within our organization. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contact Us” section that can be found on almost every page of this Site.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Choices
      </div>
      <div className='PrivacyDetails-content watch'>
      If you no longer want to receive marketing-related emails from us on a going-forward basis, you may opt-out by using the mechanism contained in each such email.
      </div>
      <div className='PrivacyDetails-content watch'>
      If you are a resident of California, under 18 and a registered user of the Site, you may ask us to remove content or information that you have posted to the Site by writing to info@crossight.in Please note that your request does not ensure complete or comprehensive removal of the content or information, as for example, some of your content may have been reposted by another user.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Use of Site by Minors
      </div>
      <div className='PrivacyDetails-content watch'>
      The Site is not directed to individuals under the age of eighteen (18) and, we do not knowingly collect Personal Information from individuals under 18.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Jurisdictional Issues
      </div>
      <div className='PrivacyDetails-content watch'>
      The Site is controlled and operated by us from India and is not intended to subject us to the laws or jurisdiction of any state, country or territory other than that of India.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Revisions to the Policy
      </div>
      <div className='PrivacyDetails-content watch'>
      If we change this privacy policy we will post any updates here for your review. The “Last Updated” line at the top of this page indicates when this Privacy Policy was last revised. Any changes will become effective when we post the revised Privacy Policy on the Site. Your use of the Site following these changes means that you accept the revised Privacy Policy.
      </div>
      <div className='PrivacyDetails-heading watch'>
      Contacting Us
      </div>
      <div className='PrivacyDetails-content watch'>
      If you have any questions about this website privacy policy, or if you would like to update any personal information you have provided to us, please contact us at:
      </div>
      <div className='PrivacyDetails-heading watch'>
      Email Address:
      </div>
      <div className='PrivacyDetails-content watch'>
      Please feel free to contact us if you have any questions or concerns about CrossRight’s Privacy Policy or our privacy practices, including if you need to access this Policy in an alternative format, or if you wish to lodge a complaint about our privacy practices. You may contact us at <a rel="noreferrer" href="mailto:info@crossright.in" target="_blank">info@crossright.in</a>.
      </div>
    </div>
    </>
    
  );
}

export default PrivacyDetails;
