import './Styles/Career.css';
import './Styles/Animation.css';
// import LogoReveal from './LogoReveal';
import Navbar2 from './Navbar2';
import { useEffect, useState } from 'react';
import ReadyToChoose from './ReadyToChoose';
import Footer from './Footer';
import CareerHero from './CareerHero';
import CareerWhy from './CareerWhy';
import CareerWhat from './CareerWhat';
import CareerJobs from './CareerJobs';
import HoveringBar from './HoveringBar';

function Career() {
  const [loaded,setLoaded]=useState(false);

  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{
    Animate();
    setTimeout(()=>{
      setLoaded(true);
    },2000)
  })
  return (
    <div className="Career">
      <Navbar2 noAnimation={true}/>
      {
        loaded &&
        <>
          <CareerHero />
          <CareerWhy />
          <CareerWhat />
          <CareerJobs />
          <Footer />
          <HoveringBar />
        </>
        
      }
      
    </div>
  );
}

export default Career;
