import './Styles/CareerWhy.css';
import './Styles/Animation.css';
import office4 from './Resources/office4.jpeg'
import office1 from './Resources/office1.jpeg'
import office2 from './Resources/office2.jpeg'
import office3 from './Resources/office3.jpeg'
import { useEffect } from 'react';

function CareerWhy() {

  
  function Animate(){
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // It's visible. Add the animation class here!
          if(entry.target.classList.contains("watch2")){
            entry.target.classList.add("inView2");
          }
          else if(entry.target.classList.contains("watch")){
            entry.target.classList.add("inView");
          }
          
        }
        else{
          entry.target.classList.remove("inView");
          entry.target.classList.remove("inView2");
        }
      });
    });
    
    // Tell the observer which elements to track
    let elementsToWatch=document.querySelectorAll('.watch');
    elementsToWatch.forEach(element=>{
      observer.observe(element);
    })
    let elementsToWatch2=document.querySelectorAll('.watch2');
    elementsToWatch2.forEach(element=>{
      observer.observe(element);
    })
  
  }
  useEffect(()=>{Animate();},[])

  

  return (
    <div className="CareerWhy">
      
      <div className='CareerWhy-card'>
        <div className='CareerWhy-card-title watch'>
        Why join us?
        </div>
        <div className='CareerWhy-card-detail watch'>
        When you join CrossRight, you work with global clients and help with their unique challenges. We are on our way to becoming the most prominent advisory firm in <span>USA and India</span>, and we continue to expand our presence globally.
        <br/><br/>
        We believe in an initiative-led culture where every team member can take the lead and do their best. We are pioneers, enablers, and achievers. Above all, we are human.

        </div>
      </div>
      {/* <div className='CareerWhy-card'>
        <div className='CareerWhy-card-title'>
        What makes CrossRight a great place to work?
        </div>
        <div className='CareerWhy-card-detail'>
        <ul>
          <li>Constructive feedback</li>
          <li>No micromanagement</li>
          <li>Learning & development</li>
          <li>Global projects</li>
          <li>Inclusive & open culture</li>
        </ul>
        </div>
      </div> */}
      <div  className='CareerWhy-card'>
          <div className='CareerWhy-card-photos watch'>
            <div className='CareerWhy-card-photos-photo'>
              <img src={office4} alt="Office Photo"/>
            </div>
            <div className='CareerWhy-card-photos-photo'>
              <img src={office2} alt="Office Photo"/>
            </div>
            <div className='CareerWhy-card-photos-photo'>
              <img src={office3} alt="Office Photo"/>
            </div>
            <div className='CareerWhy-card-photos-photo'>
              <img src={office1} alt="Office Photo"/>
            </div>
          </div>
      </div>
    </div>
  );
}

export default CareerWhy;
