import './Styles/Service.css';
import './Styles/Animation.css';
// import LogoReveal from './LogoReveal';
import Navbar2 from './Navbar2';
import { useEffect, useState } from 'react';
import ReadyToChoose from './ReadyToChoose';
import Footer from './Footer';
import ServiceServices from './ServiceServices';
import ServiceHero from './ServiceHero';
import HoveringBar from './HoveringBar';

function Service() {
  const [loaded,setLoaded]=useState(false);
  useEffect(()=>{
    setTimeout(()=>{
      setLoaded(true);
    },2000)
  })
  return (
    <div className="Service">
      <Navbar2 noAnimation={true}/>
      {
        loaded &&
        <>
          <ServiceHero />
          <ServiceServices />

          <ReadyToChoose />
          <Footer />
          <HoveringBar />
        </>
        
      }
      
    </div>
  );
}

export default Service;
