import './Styles/Hero.css';
import './Styles/Animation.css';
import chevronDown from './Resources/chevron.svg'
import chart from './Resources/home.png'
import { useEffect } from 'react';


function Animate(){
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // It's visible. Add the animation class here!
        if(entry.target.classList.contains("watch2")){
          entry.target.classList.add("inView2");
        }
        else if(entry.target.classList.contains("watch")){
          entry.target.classList.add("inView");
        }
        
      }
      else{
        entry.target.classList.remove("inView");
        entry.target.classList.remove("inView2");
      }
    });
  });
  
  // Tell the observer which elements to track
  let elementsToWatch=document.querySelectorAll('.watch');
  elementsToWatch.forEach(element=>{
    observer.observe(element);
  })
  let elementsToWatch2=document.querySelectorAll('.watch2');
  elementsToWatch2.forEach(element=>{
    observer.observe(element);
  })

}


function Hero(props) {
  const {
    dynamicHeadingText,
    extraText
  }=props
 
  
  useEffect(() => {
    let timeoutID2;
    let i=0;

    const timeoutID = setInterval(()=>{
      let dynamicSpan=document.getElementsByClassName("dynamicTextheader");
      if(dynamicSpan.length>0){
        dynamicSpan[0].classList.remove("showDynamic");
        dynamicSpan[0].classList.add("hideDynamic");
        timeoutID2 = setTimeout(()=>{
          if(i<dynamicHeadingText.length-1){
            dynamicSpan[0].innerHTML=dynamicHeadingText[++i]
          }
          else{
            i=0;
            dynamicSpan[0].innerHTML=dynamicHeadingText[i]
          }
          dynamicSpan[0].classList.remove("hideDynamic");
          dynamicSpan[0].classList.add("showDynamic");
          
          
        },500)
      }
    },3000)

    

    const timeOut3=setTimeout(()=>{
      Animate();
    },3000);

    
    return () => {
      // 👇️ clear timeout when the component unmounts
      clearInterval(timeoutID);
      clearTimeout(timeoutID2);
      clearTimeout(timeOut3);
    };
  });

  

  return (
    <div className="Hero">
      <div className='Hero-main'>
        {/* <h1 className='Hero-main-header'>{heading} <span className='Hero-main-header-dynamic' id="dynamicTextheader">{dynamicHeadingText[0]}</span>.</h1> */}
        <h1 className='Hero-main-header section watch2'>Outsourced financial <br/> services that multiply<br/> your <span className='Hero-main-header-dynamic dynamicTextheader'>{dynamicHeadingText[0]}</span></h1>
        <h3 className='Hero-main-header-sub section watch2'>{extraText}</h3>
        <button className='Hero-main-action section watch'>Why us?</button>
      </div>
      <img className='Hero-chevron watch' src={chevronDown} alt="down" />
      <div className='chart-svg watch2'>
        <img src={chart} alt="chart" />
      </div>
    </div>
  );
}

export default Hero;
